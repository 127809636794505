import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/libs/utils'

const buttonVariants = cva(
  'inline-flex gap-2 items-center justify-center whitespace-nowrap text-base font-body font-semibold ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300',
  {
    variants: {
      variant: {
        primaryDark: 'bg-purple text-white',
        primaryYellow: 'bg-yellow text-purple',
        secondaryDark: 'bg-none border border-purple3 text-purple3',
        secondaryYellow: 'bg-none border border-yellow text-yellow',
        secondaryWhite: 'bg-none border border-white text-white',
        textOnly: 'bg-none text-purple3 font-normal',
      },
      size: {
        default: 'px-6 py-5',
        small: 'px-4 py-2',
        large: 'px-10 py-5',
        textOnly: 'px-1 py-1',
      },
      disabled: {
        true: 'pointer-events-none bg-grey4 text-grey1',
      },
    },
    defaultVariants: {
      variant: 'primaryDark',
      size: 'default',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  disabled?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, disabled, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, disabled, className }))}
        ref={ref}
        role="button"
        disabled={disabled}
        {...props}
      />
    )
  },
)
Button.displayName = 'Button'

export { Button, buttonVariants }
