import { useAppStore } from '@/context/useAppStore'

const useCleanNotifications = () => {
  const { setAppStore } = useAppStore()

  return () => {
    setAppStore({ errors: { server: false, network: false } })
  }
}

export default useCleanNotifications
