import { requestWithRetry } from '../axios'

export const getPlan = async (order_uid: string, signal?: AbortSignal) => {
  try {
    return await requestWithRetry(
      `upgrade/seats?order_uid=${order_uid}`,
      'get',
      undefined, // No data for GET requests
      undefined, // No custom headers
      signal, // Pass signal here
    ).catch((err) => {
      console.log('Error fetching plan', err)
      return err
    })
  } catch (error: any) {
    console.log('getPlan Error:', error)
    return error.response?.data
  }
}
