'use client'

import * as React from 'react'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { DayPicker } from 'react-day-picker'
import 'react-day-picker/dist/style.css'

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
  eventStartDate: Date
  highlightedDates?: Date[] // Dates to mark with a dot
  currentMonth?: Date
  setCurrentMonth: Dispatch<SetStateAction<Date | undefined>>
}

function Calendar({
  className,
  eventStartDate,
  highlightedDates = [],
  classNames,
  currentMonth,
  setCurrentMonth,
  ...props
}: CalendarProps) {
  const [loading, setLoading] = useState(false)

  const handleMonthChange = async (month: Date) => {
    await setLoading(true)
    await setCurrentMonth(month) // Update the month when the user navigates
  }

  useEffect(() => {
    setLoading(false)
  }, [highlightedDates])

  return (
    <DayPicker
      startMonth={eventStartDate}
      disabled={{ before: eventStartDate }}
      defaultMonth={currentMonth}
      onMonthChange={handleMonthChange}
      today={eventStartDate}
      className={`${loading && 'opacity-40 pointer-events-none'} animate ease-out duration-200`}
      modifiers={{
        highlighted: highlightedDates.length > 0 ? highlightedDates : undefined, // Ensures it's set
      }}
      modifiersClassNames={{
        highlighted: 'highlighted', // Assigns a class to modified days
      }}
      classNames={{
        day: 'relative', // Ensure default day is positioned correctly
      }}
      {...props}
    />
  )
}

Calendar.displayName = 'Calendar'

export { Calendar }
