import {
  ArrowUp,
  PeopleIcon,
  StarIcon,
} from '@/assets/contentChannel/TilesIcons'
import {
  useContentChannelStore,
  VIEW_STATES,
} from '@/context/useContentChannelStore'
import { useFullScreenContentStore } from '@/context/useFullScreenContentStore'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import { useSeatMap } from '@/hooks/useSeatMap'
import { StaticContentItem, TileItem, TileType } from '@/types/preshow'
import TheatreIcon from '@/assets/theatreIcon.png'
import { VipDrinksIcon } from '@/assets/vipIcon'

export const getTileIcon = (title: string) => {
  // Define the Titles enum
  enum Titles {
    MEET_THE_CAST = 'Meet the Cast',
    MEET_THE_CHARACTERS = 'Meet the Characters',
    UPGRADE = 'Upgrade',
    DRINKSMORE = 'Drinks & More',
  }

  // Map titles to their corresponding icon components using the enum values as keys
  const iconMap: { [key: string]: JSX.Element | null } = {
    [Titles.MEET_THE_CAST]: <PeopleIcon />,
    [Titles.MEET_THE_CHARACTERS]: (
      <img src={TheatreIcon} alt="theatre-icon" className="w-8 h-8" />
    ),
    [Titles.UPGRADE]: <ArrowUp />,
    [Titles.DRINKSMORE]: <VipDrinksIcon />,
  }

  // Return the appropriate icon or a default icon if title not found
  // Ensure the title is matched with enum value
  return iconMap[title.trim()] || <StarIcon /> // Default to StarIcon if no match
}

// Create a custom hook that returns an action handler function
export const useActionPerTileType = () => {
  const { channelContent, setView, setBlockSwipeY, setScrollPosition } =
    useContentChannelStore()
  const { setMainContent } = useFullScreenContentStore()
  const { setSearchingSeats } = useSeatMap()
  const callRouteWithDirection = useCallRouteWithDirection()

  // Return a function that handles the action
  return (tile: TileItem) => {
    // Save the current scroll position
    setScrollPosition(window.scrollY)

    switch (tile.tile_type) {
      case TileType.CAST:
        setBlockSwipeY(true)
        setView(VIEW_STATES.MEET_THE_CAST)
        break
      case TileType.TRIGGER:
        setSearchingSeats(true)
        callRouteWithDirection('/seat-map?content=1', false, 1)
        break
      case TileType.STATIC:
        setView(VIEW_STATES.CAROUSEL)
        setMainContent({
          event_name: channelContent.assets.event_name,
          badge: channelContent.assets.badge,
          video_title: (tile.content as StaticContentItem).title,
          video_url: (tile.content as StaticContentItem).content,
          video_description: (tile.content as StaticContentItem)
            .description as string,
          link: (tile.content as StaticContentItem).link
            ? {
                type: (tile.content as StaticContentItem).link.type,
                url: (tile.content as StaticContentItem).link.url,
              }
            : undefined,
        })
        break
      default:
        break
    }
  }
}
