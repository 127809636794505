import { ReactNode } from 'react'
import { User } from '../types'
import { create } from 'zustand'

// Define the store's state
type UserState = {
  user: User | undefined
  setUser: (user: User) => void
  unsetUser: () => void
  signUpForm: boolean
  setSignUpForm: (value: boolean) => void
}

// Create the store
export const useUserStore = create<UserState>((set) => ({
  user: undefined, // initial state
  setUser: (user) => set({ user }),
  unsetUser: () => set({ user: undefined }),
  signUpForm: false,
  setSignUpForm: (value) => set({ signUpForm: value }),
}))

export const UserStoreProvider = ({ children }: { children: ReactNode }) => {
  return <>{children}</>
}
