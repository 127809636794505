import { Basket, Seat } from '@/types'
import { getConsecutiveSeatGroups } from '../index'
import { BasketType, useBasketStore } from '@/context/useBasketStore'

interface CartSummaryProps {
  selectedSeats: Seat[]
  basket: Basket
}

export const CartSummary = ({ selectedSeats, basket }: CartSummaryProps) => {
  const { basketType } = useBasketStore()

  const selectedSeatsByRow = selectedSeats.reduce(
    (acc, seat) => {
      if (!acc[seat.row]) {
        acc[seat.row] = []
      }

      // Check if there are already seats in the row with a different location
      const hasDifferentLocation = acc[seat.row].some(
        (existingSeat) => existingSeat.location !== seat.location,
      )

      if (!hasDifferentLocation) {
        // If the location of the current seat matches with the existing seats in the row, add it to the array
        acc[seat.row].push(seat)
      } else {
        // If the location is different, create a new array for seats with this location
        if (!acc[seat.row + '_' + seat.location]) {
          acc[seat.row + '_' + seat.location] = []
        }
        // Add the current seat to the new array
        acc[seat.row + '_' + seat.location].push(seat)
      }

      return acc
    },
    {} as Record<string, Seat[]>,
  )

  const rows = Object.keys(selectedSeatsByRow)

  return (
    <>
      <div className="border-b border-grey2 pb-4">
        {' '}
        {/* Wrap all mapped content in a single parent div */}
        {rows.map((row) => {
          const seats = selectedSeatsByRow[row]

          // Check if seats are consecutive
          const consecutiveGroups = getConsecutiveSeatGroups(
            seats.sort((a: any, b: any) => a.number - b.number),
          )

          if (consecutiveGroups.length > 0) {
            // Consecutive seats found, render in '1-5' format
            const consecutiveSeatsInfo = consecutiveGroups
              .map((group) => {
                return `${group[0].number}-${group[group.length - 1].number}`
              })
              .sort((a: any, b: any) => a - b) // Sort consecutive seats in ascending order

            // Include non-consecutive seats in the same paragraph
            const nonConsecutiveSeatsInfo = seats
              .filter((seat) => !consecutiveGroups[0].flat().includes(seat))
              .sort((a: any, b: any) => a.number - b.number) // Sort non-consecutive seats in ascending order
              .map((seat) => `${seat.number}`)

            return (
              <div
                key={seats[0].seat_id}
                className="flex justify-between items-center pl-1"
              >
                <div className="flex flex-col font-light gap-1">
                  <span className="font-semibold text-nowrap">
                    {seats[0].location}
                  </span>
                  <p className="text-sm flex gap-1">
                    <span className="text-balance">
                      {`Row ${row.split('_')[0]}, Seats`}
                      {` `}
                      {consecutiveSeatsInfo.join(' / ')}
                      {nonConsecutiveSeatsInfo.length > 0 &&
                      consecutiveSeatsInfo.length > 0
                        ? ' / '
                        : ''}
                      {nonConsecutiveSeatsInfo.join(' / ')}
                    </span>
                  </p>
                </div>
                <div className="flex flex-col gap-1 items-end">
                  <p className="flex font-light justify-center items-center gap-2">
                    <span className="uppercase text-sm text-white font-normal px-2 py-[2px] bg-red text-nowrap rounded-[20px]">
                      {`- ${(seats[0].discount * 100).toFixed(0)} %`}
                    </span>
                    <span className="line-through">{`£${seats[0].original_price.toFixed(2)}`}</span>
                  </p>
                  <p className="font-bold text-red flex justify-center items-center gap-2">
                    <span className="font-light text-purple text-sm">
                      {`${seats[0].number_tickets}x`}
                      {` £${seats[0].upgrade_price.toFixed(2)}`}
                    </span>
                  </p>
                </div>
              </div>
            )
          } else {
            return (
              <div
                key={seats[0].seat_id}
                className="flex justify-between items-center pl-1"
              >
                <div className="flex flex-col font-light gap-1">
                  <span className="font-semibold text-nowrap">
                    {seats[0].location}
                  </span>
                  <p className="flex gap-1">
                    <span className="text-balance">
                      {`Row ${row.split('_')[0]},`}
                      {` `}
                      {`${seats.map((seat) => seat.number.toString()).join(' / ')}`}
                    </span>
                  </p>
                </div>
                <div className="flex flex-col gap-1 items-end">
                  <p className="flex font-light justify-center items-center gap-2">
                    <span className="uppercase text-sm text-white font-normal px-2 py-[2px] bg-red text-nowrap rounded-[20px]">
                      {' '}
                      {`- ${(seats[0].discount * 100).toFixed(0)} %`}
                    </span>
                    <span className="line-through">{`£${seats[0].original_price.toFixed(2)}`}</span>
                  </p>
                  <p className="font-bold text-red flex justify-center items-center gap-2">
                    <span className="font-light text-purple text-sm">
                      {`${seats[0].number_tickets}x`}
                      {` £${seats[0].upgrade_price.toFixed(2)}`}
                    </span>
                  </p>
                </div>
              </div>
            )
          }
        })}
      </div>
      {/* <div className="flex justify-between border-y border-grey2 py-4">
        <p>{`Upgrade fee`}</p>
        <p className="flex gap-1 items-center">
          <span>{`${basket?.seats.length} x`}</span>
          {`£${basket?.seats
            .reduce((sum, seat) => {
              return sum + 2 / basket.seats.length
            }, 0)
            .toFixed(2)}`}
        </p>
      </div> */}
      <div className="flex justify-between font-semibold pt-4">
        <p>{`Total`}</p>
        <p className="flex justify-center items-center gap-2">
          {basketType === BasketType.UPGRADE && (
            <span className="bg-purple text-white text-sm font-light rounded-[40px] px-3 py-1">
              {`Save £ ${selectedSeats[0].saved_price.toFixed(0)}`}
            </span>
          )}
          {`£${(basket?.total_price).toFixed(2)}`}
        </p>
      </div>
    </>
  )
}
