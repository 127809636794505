import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog'

import { Plus } from 'lucide-react'

interface PopModalProps {
  id?: string
  title: React.ReactNode
  description: React.ReactNode
  buttonText?: string
  open?: boolean
  closeIcon?: boolean
  onClick?: () => void
}

export const PopModal = ({
  title,
  description,
  open,
  buttonText,
  closeIcon,
  onClick,
}: PopModalProps) => {
  return (
    <AlertDialog open={open}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle className="font-body">
            <div className="flex justify-between items-center">
              {title}
              {closeIcon && (
                <AlertDialogCancel className="p-0 m-0" data-testid="closeModal">
                  <Plus
                    width={24}
                    height={24}
                    className="z-10 rotate-45 text-purple font-light"
                  />
                </AlertDialogCancel>
              )}
            </div>
          </AlertDialogTitle>
          <AlertDialogDescription>{description}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction
            className="bg-purple text-yellow w-full py-4"
            onClick={onClick}
          >
            {buttonText}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
