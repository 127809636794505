export enum ActionType {
  TILE = 'tile',
  EXTERNAL = 'external',
  SHARE = 'share',
}

export enum TileType {
  STATIC = 'static',
  CAST = 'cast',
  TRIGGER = 'trigger',
}

export interface TileItem {
  title: string
  tile_type: TileType
  y_axis: number
  content?: CastContentItem[] | StaticContentItem | string
  description?: string
  preview?: string
}

export interface StaticContentItem {
  title: string
  description: string
  content: string
  link: {
    type: LinkType
    url: string
  }
  extra_params: any
}
export interface Channel {
  uid: string
  assets: {
    event_name: string
    badge: string
  }
  description: string
  main: ChannelItem[]
  tiles: TileItem[]
  actions: ChannelAction[]
}

export enum LinkType {
  EXTERNAL = 'external',
  INSTAGRAM = 'instagram',
}

export interface MainContent {
  event_name: string
  video_title: string
  video_description: string
  badge: string
  video_url: string
  link?: {
    type: LinkType
    url: string
  }
}

export interface ChannelItem {
  content: string
  title: string
  description: string | null
  y_axis: number
}

export interface CastContentItem {
  title: string
  x_axis: number
  description: string
  content: string
  content_length: number
  link?: {
    type: LinkType
    url: string
  }
  extra_params: {
    [key: string]: string
  }
  link_url?: string
}

export interface BaseChannelAction {
  action_type: ActionType
  title: string
}

export interface LinkChannelAction extends BaseChannelAction {
  link_url: string
}

export interface ContentChannelAction extends BaseChannelAction {
  content: {
    external_link?: string
    body?: string
    subject?: string
  }
}

export type ChannelAction = LinkChannelAction | ContentChannelAction
