import { useEffect, useState } from 'react'

interface LoadingIndicatorProps {
  dark?: boolean
}

const LoadingIndicator = ({ dark }: LoadingIndicatorProps) => {
  const items: number[] = [0, 1, 2]
  const [active, setActive] = useState<number>(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setActive((prevActive) =>
        prevActive < items.length - 1 ? prevActive + 1 : 0,
      )
    }, 300)

    return () => clearInterval(interval)
  }, [items.length])

  return (
    <div
      data-testid="loading-indicator"
      className="loading-indicator flex gap-1 justify-center items-center"
    >
      {items.map((number) => (
        <div
          key={number}
          className={`h-4 w-4 border transition rounded-sm ${
            dark ? `border-purple5` : `border-yellow`
          } ${
            active === number
              ? dark
                ? 'bg-purple2'
                : 'bg-yellow'
              : 'bg-transparent'
          }`}
        ></div>
      ))}
    </div>
  )
}

export default LoadingIndicator
