import { requestWithRetry } from '../axios'

type UserUpdateFields = {
  firstName?: string
  lastName?: string
  phoneNumber?: string
  cookies?: boolean
  marketing?: boolean
}

export const updateUser = async (fields: UserUpdateFields) => {
  try {
    // Only include fields that are provided (not undefined)
    const payload = {
      ...(fields.firstName !== undefined && { first_name: fields.firstName }),
      ...(fields.lastName !== undefined && { last_name: fields.lastName }),
      ...(fields.phoneNumber !== undefined && {
        phone_number: fields.phoneNumber,
      }),
      ...(fields.cookies !== undefined && { cookies: fields.cookies }),
      ...(fields.marketing !== undefined && { marketing: fields.marketing }),
    }

    return await requestWithRetry('upauth/details', 'patch', payload)
  } catch (error) {
    console.log('updateUser Error:', error)
    throw error // Re-throw to let the caller handle it
  }
}
