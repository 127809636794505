import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import * as React from 'react'
import { Check } from 'lucide-react'
import { cn } from '@/libs/utils'

// Define the variant types
type CheckboxVariant = 'primary' | 'secondary'

// Extend the props to include the variant
interface CheckboxProps
  extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  variant?: CheckboxVariant
}

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(({ className, variant = 'primary', ...props }, ref) => {
  // Define styles for each variant
  const variantStyles = {
    primary: cn(
      'border-purple4 border-[2px] data-[state=checked]:bg-purple4 data-[state=checked]:text-white',
    ),
    secondary: cn(
      'border-gray-400 border-[2px] data-[state=checked]:bg-yellow data-[state=checked]:text-purple data-[state=checked]:border-none [&>span>svg]:w-[9px] [&>span>svg]:bottom-[-.75px]',
    ),
  }

  return (
    <CheckboxPrimitive.Root
      ref={ref}
      className={cn(
        'peer h-4 w-4 shrink-0 rounded-sm focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:border-slate-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300 dark:data-[state=checked]:bg-slate-50 dark:data-[state=checked]:text-slate-900',
        variantStyles[variant], // Apply variant styles
        className,
      )}
      {...props}
    >
      <CheckboxPrimitive.Indicator
        className={cn('flex items-center justify-center text-current')}
      >
        <Check
          className={cn(
            'h-4 w-4 relative bottom-[2px]',
            variant === 'primary' ? 'text-purple3' : 'text-purple bottom-0', // Adjust icon color based on variant
          )}
          strokeWidth={5}
        />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  )
})
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
