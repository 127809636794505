import { z } from 'zod'

export const changePasswordFormSchema = z
  .object({
    currentPassword: z
      .string()
      .min(1, { message: 'Password is required' })
      .regex(
        /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/,
        'Must be 8+ characters with at least one uppercase letter, one digit and one symbol',
      ),
    newPassword: z
      .string()
      .min(1, { message: 'New Password is required' })
      .regex(
        /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/,
        'Must be 8+ characters with at least one uppercase letter, one digit and one symbol',
      ),
    confirmPassword: z.string().optional(),
  })
  .refine((data) => data.currentPassword !== data.newPassword, {
    message: 'New password cannot be the same as the current password.',
    path: ['newPassword'],
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: 'Passwords do not match.',
    path: ['confirmPassword'],
  })

export type changePasswordFormSchemaType = z.infer<
  typeof changePasswordFormSchema
>
