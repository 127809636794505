import { BookingDetails } from '@/components/upseat-ui/seatMap/BookingDetails/index'
import DefaultLayout from '@/layouts/DefaultLayout'
import HeadlineBar from '@/components/upseat-ui/general/HeadlineBar'
import RouteTransition from '@/components/routeTransition/RouteTransition'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import useExistingBookingStore from '@/context/useExistingBookingStore'
import { useNavigate } from 'react-router-dom'
import { Calendar } from '@/components/ui/calendar'
import { useEffect, useState } from 'react'
import {
  formatDateTime,
  getLastDayOfMonth,
  getFirstDayOfMonth,
  timeString,
  getNextDay,
} from '@/libs/utils'
import { motion } from 'framer-motion'
import { postSwapSeatSelected } from '@/services/Swap/postSwapSeatSelected'
import { toast } from '@/hooks/useToast'
import { deleteSwapBasket } from '@/services/Swap/deleteSwapBasket'
import { BasketType, useBasketStore } from '@/context/useBasketStore'
import { useSwapStore } from '@/context/useSwapStore'
import { getSwapSeats } from '@/services/Swap/getSwapSeats'
import { SwapDrawer } from '@/components/upseat-ui/swaps/swapDrawer'
import { Loader2 } from 'lucide-react'
interface SwapDatesProps {
  instance_uid: string
  date: string
  available: boolean
  direct: boolean
}

export const SwapDates = () => {
  const callRouteWithDirection = useCallRouteWithDirection()
  const navigate = useNavigate()
  const { order, booking } = useExistingBookingStore()
  const { basket, setBasket, setBasketType } = useBasketStore()
  const { setSwapUid, setNewSeats, setNewEventDate, setNewEventTime } =
    useSwapStore()
  const [loading, setLoading] = useState(true)
  const [cartLoading, setCartLoading] = useState(false)
  const [error, setError] = useState<string | undefined>()
  const [availableDates, setAvailableDates] = useState<Date[]>([])
  const [selectedDate, setSelectedDate] = useState<Date | undefined>()
  const [currentMonth, setCurrentMonth] = useState<Date | undefined>(undefined)
  const [swapSeatArr, setSwapSeatArr] = useState<SwapDatesProps[]>([])

  const goBack = async () => {
    if (basket) {
      await deleteSwapBasket(basket?.uid as string)
    }

    callRouteWithDirection('/tickets?back=1', true, 1)
  }

  const fetchAvailableDates = async () => {
    const eventDate = booking?.start_time ? new Date(booking.start_time) : null
    const monthDate = currentMonth ? new Date(currentMonth) : null

    const checkAvailability = async (startDate: string, endDate: string) => {
      try {
        const res = await getSwapSeats(order?.uid as string, startDate, endDate)

        if (!res) {
          return null
        }
        // Set swapSeatArr to be used in the Drawer
        setSwapSeatArr(res)

        // Filter available dates
        const availableDatesArray = res
          .filter(
            (date: { available: any; direct: any }) =>
              date.available && date.direct,
          )
          .map((date: { date: string | number | Date }) => new Date(date.date))

        return availableDatesArray
      } catch (err) {
        console.log(err)
        return null
      }
    }

    // Check availability in the current month
    let availableDates = await checkAvailability(
      monthDate && eventDate && eventDate < monthDate
        ? getFirstDayOfMonth(monthDate)
        : getNextDay(eventDate as Date),
      getLastDayOfMonth(monthDate ?? eventDate!),
    )

    // If no availability in the current month, check the next month
    if (!availableDates || availableDates.length === 0) {
      const nextMonthDate = new Date(monthDate ?? eventDate!)
      nextMonthDate.setMonth(nextMonthDate.getMonth() + 1)

      availableDates = await checkAvailability(
        getFirstDayOfMonth(nextMonthDate),
        getLastDayOfMonth(nextMonthDate),
      )

      setCurrentMonth(nextMonthDate)
    }

    if (availableDates && availableDates.length > 0) {
      setAvailableDates(availableDates)
    } else {
      toast({
        title: 'No available dates found',
        description: 'Please try again later.',
        variant: 'destructive',
      })
      goBack()
    }

    setLoading(false)
  }

  useEffect(() => {
    if (!booking) {
      navigate('/tickets')
    }
    fetchAvailableDates()
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-sparse-arrays
  }, [, currentMonth])

  useEffect(() => {
    if (selectedDate) {
      setCartLoading(true)
      postSwapSelected()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate])

  const getInstanceUid = () => {
    if (!selectedDate) return
    const selectedDateStr = `${selectedDate.getFullYear()}-${String(selectedDate.getMonth() + 1).padStart(2, '0')}-${String(selectedDate.getDate()).padStart(2, '0')}`

    return swapSeatArr.find((swapDate: SwapDatesProps) => {
      return (
        (swapDate.date.split('T')[0] as string) === (selectedDateStr as string)
      )
    })?.instance_uid
  }

  const postSwapSelected = async () => {
    const instanceUid = await getInstanceUid()
    if (!instanceUid) {
      setCartLoading(false)
      setError('No seats available for this date.')
      return
    }
    await postSwapSeatSelected(order?.uid as string, instanceUid as string)
      .then(async (res) => {
        const formattedDateTime = formatDateTime(selectedDate as Date) as {
          date: string
          time: string
        }

        setSwapUid(res.swap_uid)
        setBasketType(BasketType.SWAP)
        setBasket({
          seats: res.seats,
          swap_uid: res.swap_uid,
          uid: res.basket.uid,
          expires_utc: res.basket.expires_utc,
          total_price: res.basket.total_price,
          unit_price: res.basket.unit_price,
          number_tickets: res.basket.number_tickets,
        })
        setNewSeats(res.seats)
        setNewEventDate(formattedDateTime.date)
        setNewEventTime(
          timeString(
            availableDates.find(
              (availableDate) =>
                selectedDate &&
                availableDate.toISOString().split('T')[0] ===
                  `${selectedDate.getFullYear()}-${String(selectedDate.getMonth() + 1).padStart(2, '0')}-${String(selectedDate.getDate()).padStart(2, '0')}`,
            ) as Date,
          ),
        )
      })
      .catch(async (err) => {
        toast({
          title: err.data.error.msg,
          description: 'Please try again.',
          variant: 'destructive',
        })
        setSelectedDate(undefined)
      })
      .finally(() => {
        setCartLoading(false)
      })
  }

  return (
    <DefaultLayout>
      <RouteTransition>
        <div className="flex flex-col justify-start">
          <HeadlineBar
            title={
              <div className="flex items-center capitalize">{`swap your dates`}</div>
            }
            goBack={goBack}
          />
          <div className="select-seats w-full">
            <div
              data-testid="swap-dates"
              className={`bg-white text-purple h-[90vh] pb-[20vh] flex flex-col`}
            >
              {booking && (
                <>
                  <BookingDetails bookingData={booking} />
                  {loading ? (
                    <div
                      data-testid="loading"
                      className="flex justify-center items-center h-[40vh]"
                    >
                      <Loader2
                        size={32}
                        color="#19162c"
                        className="animate-spin will-change-transform relative leading-[50px] text-center inline-block"
                      />
                    </div>
                  ) : (
                    <>
                      <motion.div
                        initial={{
                          opacity: 0,
                          filter: 'blur(5px)',
                        }}
                        animate={{ opacity: 1, filter: 'blur(0)' }}
                        transition={{ delay: 0.2 }}
                      >
                        <Calendar
                          mode="single"
                          eventStartDate={booking.start_time}
                          highlightedDates={availableDates}
                          currentMonth={currentMonth}
                          setCurrentMonth={setCurrentMonth}
                          selected={selectedDate}
                          onSelect={(e) => {
                            setSelectedDate(e)
                          }}
                          className="rounded-md border"
                        />
                      </motion.div>
                      <div className="bg-purple2 text-white rounded-[20px] my-2 mx-8 p-4">
                        <p>{`Only showing dates where we’ve found equivalent seats available.`}</p>
                      </div>
                    </>
                  )}

                  <SwapDrawer
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    cartLoading={cartLoading}
                    setError={setError}
                    error={error}
                    availableDates={availableDates}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </RouteTransition>
    </DefaultLayout>
  )
}
