import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import React, { ReactNode } from 'react'
import { transitionEasing, transitionEasingReverse } from '@/libs/global-vars'
import { cva, type VariantProps } from 'class-variance-authority'
import { X } from 'lucide-react'
import { motion } from 'framer-motion'
import { cn } from '@/libs/utils'
import useScreenSize from '@/hooks/useScreenSize'

const notificationVariants = cva(
  'relative h-full p-4 border-none flex flex-col justify-center',
  {
    variants: {
      variant: {
        default: 'bg-purple3 rounded-md text-white',
        destructive: 'bg-red text-slate-50',
        error: 'bg-red rounded-md text-white',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

type NotificationProps = {
  title: string
  content: ReactNode
  icon: ReactNode
  size?: 'small' | 'large'
  onClose?: () => void
  variant?: VariantProps<typeof notificationVariants>['variant']
  className?: string
}

export const Notification = ({
  title,
  content,
  onClose,
  variant,
  className,
}: NotificationProps) => {
  const screenSize = useScreenSize()

  const transition = {
    ease: transitionEasing,
    duration: 0.325,
  }
  const transitionOut = {
    ease: transitionEasingReverse,
    duration: 0.125,
  }

  return (
    <motion.div
      transition={transition}
      initial={{ opacity: 0, translateY: 100, filter: 'blur(10px)' }}
      animate={{ opacity: 1, translateY: 0, filter: 'blur(0)' }}
      exit={{
        opacity: 0,
        translateY: 100,
        filter: 'blur(5px)',
        transition: transitionOut,
      }}
      className={`${screenSize.width > 1280 ? 'mx-[33vw] w-[-webkit-fill-available] w-[-moz-available]' : 'w-[-webkit-fill-available]'} m-4 bottom-sheet fixed z-[1000] left-0 top-0`}
    >
      <div className="relative">
        <Alert className={cn(notificationVariants({ variant }), className)}>
          <AlertTitle className="font-headline">{title}</AlertTitle>
          <AlertDescription className="text-sm opacity-90">
            {content}
          </AlertDescription>
        </Alert>
        <X
          width={20}
          height={20}
          onClick={onClose}
          role="button"
          className="absolute right-2 top-2 rounded-md p-1 text-slate-950/50 opacity-0 transition-opacity hover:text-slate-950 focus:opacity-100 focus:outline-none focus:ring-2 group-hover:opacity-100 dark:text-slate-50/50 dark:hover:text-slate-50 rotate-45"
        />
      </div>
    </motion.div>
  )
}
