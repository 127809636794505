import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { HouseIcon } from '@/assets/HouseIcon'
import { UpSeatLogo } from '@/assets/logos/upseat_logo'

interface LogoFlipProps {
  onFlip?: () => void // Optional callback when the coin flips
  flipInterval?: number // Interval for automatic flipping (in milliseconds)
}

export const LogoFlip = ({ onFlip, flipInterval = 3000 }: LogoFlipProps) => {
  const [isFlipped, setIsFlipped] = useState(false)

  // Automatically flip the coin at the specified interval
  useEffect(() => {
    const interval = setInterval(() => {
      setIsFlipped((prev) => !prev)
      onFlip?.() // Trigger the callback if provided
    }, flipInterval)

    return () => clearInterval(interval)
  }, [flipInterval, onFlip])

  return (
    <motion.div
      style={{
        perspective: '1200px', // Add perspective for 3D effect
        transformStyle: 'preserve-3d', // Ensure child elements are rendered in 3D space
      }}
      className="relative flex items-center justify-center w-8 h-8"
    >
      {/* Front Side */}
      <motion.div
        initial={{ rotateY: 0 }} // Initial state: front side visible
        animate={{ rotateY: isFlipped ? 180 : 0 }} // Flip animation
        transition={{ duration: 1, ease: 'easeInOut' }} // Smooth transition
        style={{
          position: 'absolute',
          backfaceVisibility: 'hidden', // Hide the back side when flipped
        }}
        className="flex items-center justify-center"
      >
        <motion.span className="z-50 bg-yellow [&>svg]:w-6 [&>svg]:h-6 text-white rounded-full w-8 h-8 flex items-center justify-center">
          <UpSeatLogo />
        </motion.span>
      </motion.div>

      {/* Back Side */}
      <motion.div
        initial={{ rotateY: -180 }} // Initial state: back side hidden
        animate={{ rotateY: isFlipped ? 0 : -180 }} // Flip animation
        transition={{ duration: 1, ease: 'easeInOut' }} // Smooth transition
        style={{
          position: 'absolute',
          backfaceVisibility: 'hidden', // Hide the back side when flipped
        }}
        className="flex items-center justify-center"
      >
        <motion.span className="z-50 bg-purple text-white rounded-full w-8 h-8 flex items-center justify-center">
          <HouseIcon />
        </motion.span>
      </motion.div>
    </motion.div>
  )
}
