import * as React from 'react'

import { cn } from '@/libs/utils'

type Variant = 'default' | 'dark' | 'transparent'

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  variant?: Variant
}

type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  variant?: Variant
}

const baseClasses =
  'flex w-full rounded-full px-6 py-5 text-purple file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-purple placeholder:font-light focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:border-purple4 disabled:cursor-not-allowed disabled:opacity-50'

const variantClasses = {
  default:
    'bg-purple7 border-purple7 text-purple focus-visible:outline-purple4',
  dark: 'bg-purple text-white border-purple3 border-[1.5px] placeholder:text-purple5 focus-visible:shadow-[0px_0px_4px_0px_var(--purple-04,#9A86F7)] focus-visible:bg-purple1 focus-visible:text-white',
  transparent:
    'bg-transparent border-purple5 text-white border-[1.5px] placeholder:text-purple5 focus-visible:text-white placeholder:text-white',
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, variant = 'default', ...props }, ref) => (
    <input
      type={type}
      autoComplete="off"
      className={cn(baseClasses, variantClasses[variant], className)}
      ref={ref}
      {...props}
    />
  ),
)
Input.displayName = 'Input'

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, variant = 'default', ...props }, ref) => (
    <textarea
      className={cn(baseClasses, variantClasses[variant], className)}
      ref={ref}
      {...props}
    />
  ),
)
Input.displayName = 'Textarea'

export { Input, Textarea }
