import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Arrow } from '@/assets/Arrow'
import { ExpandableText } from '@/components/upseat-ui/contentChannel/ExpandableText'
import { PlayIcon } from '@/assets/PlayIcon'
import { SeekBar } from '@/components/upseat-ui/contentChannel/SeekBar'
import { Skeleton } from '@/components/ui/skeleton'
import { VolumeOff } from '@/assets/volumeOff'
import { VolumeOn } from '@/assets/volumeOn'
import { useFullScreenContentStore } from '@/context/useFullScreenContentStore'
import useScreenSize from '@/hooks/useScreenSize'
import { useUserStore } from '@/context/useUserStore'
import {
  useContentChannelStore,
  VIEW_STATES,
} from '@/context/useContentChannelStore'
import { ExternalLink } from 'lucide-react'
import { LogoFlip } from '@/components/upseat-ui/contentChannel/logoFlipper'

export const Carousel = () => {
  const { mainContent, setFullScreenIndex } = useFullScreenContentStore()
  const { setBlockSwipeY, setView } = useContentChannelStore()
  const navigate = useNavigate()
  const { user } = useUserStore()
  const screenSize = useScreenSize()
  const videoRef = useRef<HTMLVideoElement>(null)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [isMuted, setIsMuted] = useState(true)
  const [isPlaying, setIsPlaying] = useState(false)
  const [contentLoaded, setContentLoaded] = useState(false)
  const [badgeLoaded, setBadgeLoaded] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [bookNow, setBookNow] = useState(false)
  const spring = useMemo(() => ({ mass: 5, tension: 500, friction: 80 }), [])

  useEffect(() => {
    setBlockSwipeY(false)
    if (!mainContent.video_url.length) {
      setView(VIEW_STATES.MAIN_CONTENT)
    }
  }, [mainContent.video_url.length, setBlockSwipeY, setView])

  useEffect(() => {
    contentLoaded && setIsPlaying(true)
  }, [contentLoaded])

  useEffect(() => {
    setIsPlaying(!isExpanded) // Control playback using `isPlaying`
  }, [isExpanded])

  useEffect(() => {
    const video = videoRef.current
    if (!video) return

    if (isPlaying) {
      video.muted = isMuted // Ensure muted state is applied
      video.play().catch(() => {
        setIsPlaying(false) // Handle play error gracefully
      })
    } else {
      video.pause()
    }
  }, [isPlaying, isMuted])

  useEffect(() => {
    setBookNow(false)

    setTimeout(() => {
      setBookNow(true)
    }, 2500)
  }, [])

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime)
    }
  }

  const handleLoadedMetadata = () => {
    setContentLoaded(true)
    if (videoRef.current) {
      setDuration(videoRef.current.duration)
    }
  }

  const togglePlayPause = () => {
    setIsPlaying((prev) => !prev) // Toggle playback
  }

  return (
    <AnimatePresence>
      <motion.div
        id="content-full-screen"
        className={`absolute m-0 w-full h-[100svh] flex flex-col justify-center bg-[black]`}
      >
        {!!user && (
          <div
            className="absolute z-[500] cursor-pointer top-0 right-0 h-8 w-8 m-4"
            onClick={() => navigate('/tickets', { replace: true })}
          >
            <motion.div
              transition={spring}
              onClick={() => {
                setView(VIEW_STATES.MAIN_CONTENT)
                navigate('/tickets')
              }}
              className="w-full h-full flex items-center justify-center"
            >
              <LogoFlip flipInterval={3000} />
            </motion.div>
          </div>
        )}

        <div
          className="absolute z-[500] cursor-pointer top-0 left-0 pr-6"
          onClick={() => {
            setFullScreenIndex(0)
            setView(VIEW_STATES.MAIN_CONTENT)
          }}
        >
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="relative m-4 z-50 bg-purple1 text-white rounded-full w-8 h-8 flex items-center justify-center"
          >
            <Arrow />
          </motion.span>
        </div>

        <div
          className={`relative w-full h-[100svh] transition ease-in-out delay-150`}
        >
          {contentLoaded && !isPlaying && (
            <div
              className={`absolute top-0 ${screenSize.width > 1024 ? 'w-[33vw]' : 'w-[100vw]'} h-full flex items-center justify-center`}
            >
              <div
                className="[&>svg]:relative [&>svg]:left-[1px] cursor-pointer z-[100] bg-white/50 rounded-full w-16 h-16 flex items-center justify-center"
                onClick={() => setIsPlaying(true)}
              >
                <PlayIcon />
              </div>
            </div>
          )}

          <motion.video
            initial={{ opacity: 0 }}
            animate={{ opacity: contentLoaded ? 1 : 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            ref={videoRef}
            controls={false}
            autoPlay={isPlaying}
            loop
            muted={isMuted}
            playsInline={true}
            className={`absolute z-50 top-0 w-full h-[100svh] object-cover ${contentLoaded ? '' : 'hidden'}`}
            onLoadedMetadata={handleLoadedMetadata}
            onTimeUpdate={handleTimeUpdate}
            onClick={togglePlayPause}
            src={mainContent.video_url}
          />

          <div className="absolute inset-0 z-20 pointer-events-none" />

          <motion.div
            className="w-full items-center justify-between absolute pt-24 pb-2 p-6 bottom-0 flex z-50 bg-[linear-gradient(to_top,rgba(0,0,0,.6)_50%,rgba(0,0,0,0)_100%)]"
            initial={{ opacity: 0 }}
            animate={{ opacity: mainContent ? 1 : 0 }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0, transition: { duration: 0 } }}
          >
            <div className="flex flex-col gap-2 w-full">
              {mainContent.link ? (
                <div className={`w-full flex justify-end mb-8`}>
                  <div
                    className="cursor-pointer top-0 right-0 bg-white/50 rounded-full w-8 h-8 flex items-center justify-center"
                    onClick={() => setIsMuted(!isMuted)}
                  >
                    {isMuted ? <VolumeOff /> : <VolumeOn />}
                  </div>
                </div>
              ) : null}
              <div className="flex gap-2 z-50 items-start justify-between w-full">
                <div className="flex gap-2 items-center">
                  <img
                    src={mainContent.badge}
                    alt="preshowOne"
                    draggable={false}
                    className={`w-8 h-8 rounded-[4px] ${badgeLoaded ? '' : 'hidden'}`}
                    onLoad={() => setBadgeLoaded(true)}
                  />
                  {!badgeLoaded && (
                    <Skeleton className="w-8 h-8 rounded-[4px] bg-purple" />
                  )}
                  <p className="font-bold min-w-[150px]">
                    {mainContent.video_title ?? mainContent.event_name}
                  </p>
                </div>

                {!mainContent.link ? (
                  <div className={`w-full flex justify-end`}>
                    <div
                      className="cursor-pointer top-0 right-0 bg-white/50 rounded-full w-8 h-8 flex items-center justify-center"
                      onClick={() => setIsMuted(!isMuted)}
                    >
                      {isMuted ? <VolumeOff /> : <VolumeOn />}
                    </div>
                  </div>
                ) : null}

                {mainContent.link && bookNow ? (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: bookNow ? 1 : 0 }}
                    transition={{ duration: 0.4, delay: 0.5 }}
                    exit={{ opacity: 0 }}
                    className={`relative text-sm flex justify-end items-start`}
                  >
                    <a
                      href={mainContent.link?.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className="bg-yellow font-medium flex items-center gap-1 text-purple capitalize py-2 px-5">
                        {`book now`}
                        <ExternalLink
                          size={14}
                          className="mb-[2.125px]"
                          fontWeight={'bold'}
                        />
                      </button>
                    </a>
                  </motion.div>
                ) : null}
              </div>

              {mainContent.video_description && (
                <div className="z-50">
                  <ExpandableText
                    text={mainContent.video_description}
                    limit={100}
                    isExpanded={isExpanded}
                    setIsExpanded={setIsExpanded}
                  />
                </div>
              )}

              {/* Timeline (Seek Bar) */}
              {contentLoaded && (
                <div className="relative flex flex-col items-center z-50 bg-transparent py-4">
                  <SeekBar
                    currentIndex={0}
                    videoRef={videoRef}
                    duration={duration}
                    currentTime={currentTime}
                    setCurrentTime={setCurrentTime}
                    isActive
                  />

                  {/* Custom styles to hide the default thumb */}
                  <style>{`
              input[type='range']::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 0;
                height: 0;
              }
              input[type='range']::-moz-range-thumb {
                width: 0;
                height: 0;
              }
            `}</style>
                </div>
              )}
            </div>
          </motion.div>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}
