import { requestWithRetry } from '../axios'

export const deleteSwapBasket = async (basket_uid: string) => {
  try {
    return await requestWithRetry(
      `swap/select?basket_uid=${basket_uid}`,
      'delete',
    )
  } catch (err) {
    throw err
  }
}
