import { requestWithRetry } from '../axios'

export const syncBooking = async (signal?: AbortSignal) => {
  const response = await requestWithRetry(
    'order/sync',
    'post',
    undefined,
    undefined,
    signal,
  )
  return response?.data
}
