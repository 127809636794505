import { Channel } from '@/types/preshow'
import { create } from 'zustand'

// Define the possible view states
export const VIEW_STATES = {
  CAROUSEL: 'carousel',
  FULL_SCREEN: 'fullScreen',
  MEET_THE_CAST: 'meetTheCast',
  MAIN_CONTENT: 'mainContent',
}

// Define the store's state
type ContentChannelState = {
  channelContent: Channel
  setChannelContent: (value: Channel) => void
  currentView: string
  setView: (view: string) => void
  previousView: string | null
  blockSwipeY: boolean
  setBlockSwipeY: (value: boolean) => void
  scrollPosition: number
  setScrollPosition: (value: number) => void
}

// Create the store
export const useContentChannelStore = create<ContentChannelState>((set) => ({
  channelContent: {
    uid: '',
    assets: {
      event_name: '',
      badge: '',
    },
    description: '',
    main: [],
    actions: [],
    tiles: [],
  },
  setChannelContent: (value) => set({ channelContent: value }),
  currentView: VIEW_STATES.MAIN_CONTENT,
  previousView: null,
  setView: (view) =>
    set((state) => ({
      currentView: view,
      previousView: state.currentView,
    })),
  blockSwipeY: false,
  setBlockSwipeY: (value) => set({ blockSwipeY: value }),
  scrollPosition: 0,
  setScrollPosition: (value) => set({ scrollPosition: value }),
}))
