import { useEffect, useState } from 'react'

import { CartSummary } from './CartSummary'
import DefaultLayout from '@/layouts/DefaultLayout'
import HeadlineBar from '@/components/upseat-ui/general/HeadlineBar'
import RouteTransition from '@/components/routeTransition/RouteTransition'
import { Skeleton } from '@/components/ui/skeleton'
import { Stripe } from '@/pages/payment'
import { postIntent } from '@/services/Stripe/postIntent'
import { useAppStore } from '@/context/useAppStore'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import { useCartStore } from '@/context/useCartStore'
import useEventStore from '@/context/useEventStore'
import { BasketType, useBasketStore } from '@/context/useBasketStore'
import { SwapCartSummary } from '@/pages/swapDates/bookingSummary/CartSummary'
import { deleteSwapBasket } from '@/services/Swap/deleteSwapBasket'
import { deleteBasket } from '@/services/Basket/deleteBasket'

export const BookingSummary = () => {
  const callRouteWithDirection = useCallRouteWithDirection()
  const { loadingPayment } = useCartStore()
  const { basket, removeBasket, basketType } = useBasketStore()
  const { errors, setAppStore } = useAppStore()
  const { setSelectedSeats } = useEventStore()
  const { seatsPendingPayment } = useCartStore()
  const [timeToReserve, setTimeToReserve] = useState<string>('')
  const [termsModalOpen, setTermsModalOpen] = useState(false)
  const [clientSecret, setClientSecret] = useState('')

  useEffect(() => {
    if (!termsModalOpen) {
      setTimeout(() => {
        setTermsModalOpen(true)
      }, 3000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (basket) {
      setTimeout(async () => {
        const currentTime = new Date().getTime()
        const expirationTime = new Date(basket?.expires_utc).getTime()
        if (expirationTime < currentTime) {
          await removeBasket()
          callRouteWithDirection('/tickets', false, 1)
          // callRouteWithDirection('/seat-map', true, 1)
        }

        const timeDifferenceInSeconds = Math.abs(
          (expirationTime - currentTime) / 1000,
        ) // Convert milliseconds to seconds

        // Calculate minutes and seconds
        const minutes = Math.floor(timeDifferenceInSeconds / 60)
        const seconds = Math.floor(timeDifferenceInSeconds % 60)

        // Format result into MM:SS format
        const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds
          .toString()
          .padStart(2, '0')}`
        setTimeToReserve(formattedTime)
      }, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeToReserve])

  useEffect(() => {
    if (basket) {
      postIntent(basket.uid)
        .then(async (res) => {
          await setClientSecret(res.intent.client_secret)
          await setSelectedSeats([])
        })
        .catch(async (err) => {
          console.log('postIntent Error:', err)
          await setAppStore({
            errors: {
              ...errors,
              server: true,
            },
          })
          await removeBasket()
          callRouteWithDirection('/tickets', false, 1)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleResetState = async () => {
    try {
      switch (basketType) {
        case BasketType.UPGRADE:
          // Wait for deletion to prevent stuck status
          await deleteBasket(basket?.uid as string)
          callRouteWithDirection('/tickets', false, 1)
          break
        case BasketType.SWAP:
          // Wait for deletion to prevent stuck status
          await deleteSwapBasket(basket?.uid as string)
          callRouteWithDirection('/tickets?back=1', false, 1)
          break
        case BasketType.REFUND:
          // Wait for deletion to prevent stuck status
          callRouteWithDirection('/tickets?back=1', false, 1)
          break
        default:
          // Wait for deletion to prevent stuck status
          await deleteBasket(basket?.uid as string)
          callRouteWithDirection('/tickets', false, 1)
      }
    } catch (error) {
      console.log('Failed to delete basket:', error)
    }
  }

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      handleResetState()
      event.preventDefault()
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DefaultLayout>
      <RouteTransition>
        <div className="flex flex-col justify-start gap-4">
          <HeadlineBar
            title={(() => {
              switch (basketType) {
                case BasketType.REFUND:
                  return 'Refund Summary'
                default:
                  return 'Booking Summary'
              }
            })()}
            goBack={
              !loadingPayment ? handleResetState : undefined // Use the async function here
            }
          />
        </div>
        <div
          data-testid="booking-summary"
          className="bg-white min-h-[90svh] overflow-scroll flex flex-col justify-between gap-8 text-purple p-6 pt-4"
        >
          <div>
            {timeToReserve && !loadingPayment ? (
              <div className="flex">
                <p className="bg-purple3 rounded-[20px] text-white font-body px-4 py-1 w-fit">
                  {`Reserved for `}
                  <span className="font-bold">{timeToReserve}</span>
                  {` minutes`}
                </p>
              </div>
            ) : (
              <Skeleton className="bg-purple3/30 w-[65%] h-7" />
            )}
            <div className="text-purple bg-purple7 rounded-[20px] py-6 px-4 my-4">
              <div className="flex flex-col justify-between w-full">
                {basket && basketType === BasketType.UPGRADE && (
                  <CartSummary
                    selectedSeats={seatsPendingPayment}
                    basket={basket}
                  />
                )}
                {basket && basketType === BasketType.SWAP && (
                  <SwapCartSummary basket={basket} />
                )}
              </div>
            </div>
            <Stripe clientSecret={clientSecret} />
          </div>
        </div>
      </RouteTransition>
    </DefaultLayout>
  )
}
