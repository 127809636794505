import { BasketType, useBasketStore } from '@/context/useBasketStore'
import { useCartStore } from '@/context/useCartStore'
import { useSwapStore } from '@/context/useSwapStore'
import { Basket } from '@/types'

interface CartSummaryProps {
  basket: Basket
}

export const SwapCartSummary = ({ basket }: CartSummaryProps) => {
  const { basketType } = useBasketStore()
  const { seatsPendingPayment } = useCartStore()
  const { newEventDate, newEventTime, newSeats } = useSwapStore()

  return (
    <>
      <section className="flex flex-col gap-2 mb-2">
        <div className="flex justify-between font-medium">
          <div>{newEventDate}</div>
          <div>{newEventTime}</div>
        </div>
        {newSeats && (
          <div className="flex justify-between">
            <div>{newSeats[0].plan_name}</div>
            <div>
              {`Row ` + newSeats[0].seat_name.replace(/[^a-zA-Z]/g, '') + `, `}
              {newSeats.length > 1
                ? `${newSeats[0].seat_name.replace(/\D/g, '')}-${newSeats.at(-1)?.seat_name.replace(/\D/g, '') ?? ''}`
                : newSeats[0].seat_name.replace(/\D/g, '')}
            </div>
          </div>
        )}
      </section>
      <div className="flex justify-between border-y border-grey2 py-4">
        <p>{`Swap fee`}</p>
        <p className="flex gap-1 items-center">
          <span>{`${basket?.seats.length} x`}</span>
          {`£${basket?.seats
            .reduce((sum) => {
              return sum + basket.unit_price / basket.seats.length
            }, 0)
            .toFixed(2)}`}
        </p>
      </div>
      <div className="flex justify-between font-semibold mt-2">
        <p>{`Total`}</p>
        <p className="flex justify-center items-center gap-2">
          {basketType === BasketType.UPGRADE && (
            <span className="bg-purple text-white text-sm font-light rounded-[40px] px-3 py-1">
              {`Save £` +
                seatsPendingPayment.reduce(
                  (
                    sum: number,
                    seat: { original_price: number; discount: number },
                  ) => {
                    return sum + seat.original_price * seat.discount
                  },
                  0,
                )}
            </span>
          )}
          {`£${(basket?.unit_price * basket.seats.length).toFixed(2)}`}
        </p>
      </div>
    </>
  )
}
