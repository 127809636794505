import { requestWithRetry } from '../axios'

export const deleteBasket = async (basket_uid: string) => {
  try {
    return await requestWithRetry(
      `upgrade/tickets?basket_uid=${basket_uid}`,
      'delete',
    )
  } catch (error: any) {
    return error.response?.data
  }
}
