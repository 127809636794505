import { create } from 'zustand'

// Define the store's state
type AppState = {
  loading: {
    app: boolean
    initial: boolean
  }
  currency: string
  alerts: {
    text: boolean
    email: boolean
  }
  errors: {
    server: boolean
    network: boolean
  }
  ui: {
    sideMenu: boolean
    ticketManagement: boolean
    onboarding: boolean
  }
  setAppStore: (update: Partial<AppState>) => void
}

// Create the store
export const useAppStore = create<AppState>((set) => ({
  loading: { app: false, initial: true },
  currency: 'GBP',
  alerts: { text: false, email: false },
  errors: { server: false, network: false },
  ui: {
    sideMenu: false,
    ticketManagement: false,
    onboarding: false,
  },
  setAppStore: (update) => set((state) => ({ ...state, ...update })),
}))
