import { Order } from '@/types/booking'
import { Basket } from '../types'
import { create } from 'zustand'

export enum BasketType {
  UPGRADE = 'upgrade',
  SWAP = 'swap',
  REFUND = 'refund',
}

// Define the store's state type
type BasketState = {
  basketType?: BasketType
  basket: Basket | undefined

  // for swap dates
  newEventDate?: string
  newEventTime?: string
  newOrder?: Order

  setBasket: (basket: Basket) => void
  removeBasket: () => void
  setBasketType: (basketType: BasketType) => void
  setNewEventDate: (newEventDate: string) => void
  setNewEventTime: (newEventTime: string) => void
  setNewOrder: (order: Order) => void
}

// Create the store
export const useBasketStore = create<BasketState>((set) => ({
  basketType: undefined,
  basket: undefined,
  newEventDate: undefined,
  newEventTime: undefined,
  newOrder: undefined,
  setBasket: (basket: Basket) => set({ basket }),
  removeBasket: () => set({ basket: undefined }),
  setBasketType: (basketType: any) => set({ basketType }),
  setNewEventDate: (newEventDate: string) => set({ newEventDate }),
  setNewEventTime: (newEventTime: string) => set({ newEventTime }),
  setNewOrder: (newOrder: Order) => set({ newOrder }),
}))
