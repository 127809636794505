import HeadlineBar from '@/components/upseat-ui/general/HeadlineBar'
import RouteTransition from '@/components/routeTransition/RouteTransition'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import DefaultLayout from '@/layouts/DefaultLayout'
import { useTicketsStore } from '@/context/useTicketsStore'
import { Booking, Order, OrderStatus } from '@/types/booking'
import { formattedDate } from '@/libs/utils'
import { OrdersIconDark } from '@/assets/menuNavIcons/OrdersIconDark'

export const Orders = () => {
  const callRouteWithDirection = useCallRouteWithDirection()
  const { wallet, walletOrders } = useTicketsStore()

  const getBookingFromOrder = (order: Order) =>
    wallet?.find((booking: Booking) =>
      booking.orders.some((o) => o.uid === order.uid),
    ) as Booking

  return (
    <DefaultLayout>
      <RouteTransition>
        <div className="flex flex-col justify-start gap-4">
          <HeadlineBar
            title={`orders`}
            goBack={() => callRouteWithDirection('/tickets', true, 3)}
          />
        </div>
        <main
          className="bg-white overflow-scroll flex flex-col gap-4 text-purple h-[90svh] px-4"
          data-testid="orders"
        >
          {walletOrders?.filter(
            (order: Order) => order.status !== OrderStatus.STANDARD,
          ).length ? (
            <div>
              {walletOrders
                ?.filter(
                  (order: Order) => order.status !== OrderStatus.STANDARD,
                )
                .map((order: Order, index: number) => (
                  <div
                    key={order.uid}
                    className={`flex flex-col gap-2 w-full items-start justify-between py-4 ${index > 0 && 'border-t border-grey2/70'}`}
                  >
                    <div
                      className={`text-[12px] font-light flex w-full justify-between items-center`}
                    >
                      <p className="text-grey">
                        {formattedDate(getBookingFromOrder(order)?.start_time)}
                      </p>
                      <p
                        className={`font-body font-normal px-3 py-1 rounded-md capitalize ${order.status === OrderStatus.REFUNDED ? '' : 'bg-green2/50 text-green3'}`}
                      >
                        {order.status}
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <img
                        src={getBookingFromOrder(order)?.thumbnail_url}
                        alt="eventImg"
                        className="w-10 h-10 rounded-[12px]"
                      />
                      <div className="flex flex-col gap-1 font-light justify-center">
                        <p className="text-sm font-[500]">
                          {getBookingFromOrder(order)?.event_name}
                        </p>
                        <p className="text-sm">
                          {getBookingFromOrder(order)?.theatre_name}
                        </p>
                      </div>
                    </div>
                    <p className="text-[12px] font-body text-grey/70">{`Order ID: ${order.order_id}`}</p>
                  </div>
                ))}
            </div>
          ) : (
            <div className="w-full h-full pb-[10svh] flex flex-col gap-2 justify-center items-center [&>svg]:w-10 [&>svg]:h-10 opacity-80">
              <OrdersIconDark />
              <p className="w-full text-center">{`No orders yet`}</p>
            </div>
          )}
        </main>
      </RouteTransition>
    </DefaultLayout>
  )
}
