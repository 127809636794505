import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Arrow } from '@/assets/Arrow'
import { CastContentItem, LinkType } from '@/types/preshow'
import { ExpandableText } from '../../components/upseat-ui/contentChannel/ExpandableText'
import { SeekBar } from '../../components/upseat-ui/contentChannel/SeekBar'
import { AnimatePresence, motion } from 'framer-motion'
import { useFullScreenContentStore } from '@/context/useFullScreenContentStore'
import { useMeetTheCastStore } from '@/context/useMeetTheCastStore'
import { useSwipeDirection } from '@/hooks/useSwipeDirection'
import { useUserStore } from '@/context/useUserStore'
import { ExternalLink, Redo, Undo } from 'lucide-react'
import InstagramIcon from '@/assets/instagramIcon.svg'
import {
  useContentChannelStore,
  VIEW_STATES,
} from '@/context/useContentChannelStore'
import { LogoFlip } from '@/components/upseat-ui/contentChannel/logoFlipper'

export const MeetTheCast = () => {
  const { user } = useUserStore()
  const { duration, casting } = useMeetTheCastStore()
  const { blockSwipeY, setView } = useContentChannelStore()
  const { setFullScreenIndex } = useFullScreenContentStore()
  const [isExpanded, setIsExpanded] = useState(false)
  const navigate = useNavigate()
  const { swipeDirection, resetSwipeDirection } = useSwipeDirection()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [errorLoadingImage, setErrorLoadingImage] = useState(false)
  const castingContentArray = casting?.content as CastContentItem[]
  const [userGuide, setUserGuide] = useState(castingContentArray?.length > 1)
  const [isPaused, setIsPaused] = useState(false)
  const spring = useMemo(() => ({ mass: 5, tension: 500, friction: 80 }), [])

  const [seekBarsProgress, setSeekBarsProgress] = useState<number[]>(
    casting ? Array(castingContentArray.length).fill(0) : [],
  )
  const isSeekBarCompleteRef = useRef<boolean[]>(
    casting ? Array(castingContentArray.length).fill(false) : [],
  )

  const animationFrameId = useRef<number | null>(null)

  // Handle touch start to pause video
  const handleTouchStart = () => {
    setIsPaused(true)
  }

  // Handle touch end to resume video
  const handleTouchEnd = () => {
    setIsPaused(false)
  }

  useEffect(() => {
    if (swipeDirection && !blockSwipeY) {
      setView(VIEW_STATES.FULL_SCREEN)
    }

    resetSwipeDirection()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swipeDirection])

  useEffect(() => {
    if (!casting) {
      setView(VIEW_STATES.MAIN_CONTENT)
    }

    setTimeout(() => {
      setUserGuide(false)
    }, 1500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Adjust your effect for seek bar progress to respect isPaused
  useEffect(() => {
    if (userGuide || isPaused || isExpanded) return // Respect pause states
    setSeekBarsProgress((prevProgress) =>
      prevProgress.map((progress, index) =>
        index === currentIndex ? 0 : progress,
      ),
    )

    const updateProgress = () => {
      setSeekBarsProgress((prevProgress) =>
        prevProgress.map((progress, index) => {
          if (index < currentIndex) return duration / 1000 // Completed items
          if (index === currentIndex) {
            const newProgress = Math.min(progress + 1 / 120, duration / 1000)
            isSeekBarCompleteRef.current[currentIndex] =
              newProgress >= duration / 1000
            return newProgress
          }
          return progress // Future items remain unchanged
        }),
      )

      if (!isExpanded && !isPaused) {
        animationFrameId.current = requestAnimationFrame(updateProgress)
      }
    }

    cancelAnimationFrame(animationFrameId.current || 0)
    animationFrameId.current = requestAnimationFrame(updateProgress)

    return () => cancelAnimationFrame(animationFrameId.current || 0)
  }, [duration, currentIndex, isExpanded, isPaused, userGuide])

  const handleSkipContent = (next?: boolean) => {
    if (!next && currentIndex === 0) return

    setIsExpanded(false)

    setCurrentIndex((prevIndex) => {
      const isLastItem = next && prevIndex === castingContentArray.length - 1

      setSeekBarsProgress((prevProgress) =>
        prevProgress.map((progress, index) => {
          if (isLastItem) return 0 // Reset all items when reaching the end
          if (next) {
            if (index < prevIndex) return duration / 1000 // Completed items
            if (index === prevIndex) return 0 // Reset current item
          } else {
            if (index === prevIndex) return 0 // Reset current item
            if (index === prevIndex - 1) return 0 // Reset previous item properly
            if (index < prevIndex - 1) return duration / 1000 // Keep earlier items completed
          }
          return progress
        }),
      )

      return next ? (prevIndex + 1) % castingContentArray.length : prevIndex - 1 // Ensure backward navigation works correctly
    })
  }

  return (
    <AnimatePresence>
      {castingContentArray && (
        <section
          id="meet-the-cast"
          className={`flex flex-col justify-start items-center w-full h-[100svh]`}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          {!!user && (
            <div
              className="absolute z-[500] cursor-pointer top-0 right-0 h-8 w-8 m-4"
              onClick={() => navigate('/tickets', { replace: true })}
            >
              <motion.div
                transition={spring}
                onClick={() => {
                  setView(VIEW_STATES.MAIN_CONTENT)
                  navigate('/tickets')
                }}
                className="w-full h-full flex items-center justify-center"
              >
                <LogoFlip flipInterval={3000} />
              </motion.div>
            </div>
          )}

          <div
            className="absolute z-[500] cursor-pointer top-0 left-0 pr-6"
            onClick={() => {
              setView(VIEW_STATES.MAIN_CONTENT)
              setFullScreenIndex(0)
            }}
          >
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="relative m-4 z-50 bg-purple1 text-white rounded-full w-8 h-8 flex items-center justify-center"
            >
              <Arrow />
            </motion.span>
          </div>

          {castingContentArray?.length > 1 ? (
            <motion.div
              initial={{ opacity: 1 }}
              animate={{ opacity: userGuide ? 1 : 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              className={`w-full h-[100svh] object-cover absolute top-0 z-10 flex items-center justify-between p-4 bg-[linear-gradient(to_top,rgba(0,0,0,.6)_50%,rgba(0,0,0,0)_100%)]`}
            >
              {/* Animated Undo Arrow */}
              <motion.div
                animate={{ x: [0, -10, 0] }} // Moves left (-10px) and back (0px)
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  ease: 'easeInOut',
                }}
              >
                <Undo size={30} />
              </motion.div>

              <p className="font-headline">{`tap to skip`}</p>

              {/* Animated Redo Arrow */}
              <motion.div
                animate={{ x: [0, 10, 0] }} // Moves right (10px) and back (0px)
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  ease: 'easeInOut',
                }}
              >
                <Redo size={30} />
              </motion.div>
            </motion.div>
          ) : null}

          <motion.div className="relative m-0 w-full h-full min-h-[100svh] flex flex-col justify-center">
            <div className="relative w-full h-full">
              <div
                className={`absolute top-0 z-40 w-full h-full flex ${userGuide && 'pointer-events-none'}`}
              >
                <div
                  className="w-[50%] h-[100svh]"
                  onClick={() => handleSkipContent()}
                ></div>
                <div
                  className="w-[50%] h-[100svh]"
                  onClick={() => handleSkipContent(true)}
                ></div>
              </div>

              {!errorLoadingImage && (
                <img
                  src={castingContentArray[currentIndex].content}
                  alt={castingContentArray[currentIndex].content}
                  draggable={false}
                  className={`w-full h-[100svh] object-cover absolute top-0`}
                  onError={() => setErrorLoadingImage(true)}
                />
              )}

              {errorLoadingImage && (
                <div className="w-full h-[100svh]">
                  <p className="absolute inset-0 z-20 text-white font-headline text-center flex items-center justify-center">
                    {`Error loading image`}
                  </p>
                </div>
              )}

              <div className="absolute inset-0 z-20 pointer-events-none" />
            </div>

            {!userGuide && (
              <div className="w-full items-center justify-between absolute pt-8 pb-4 p-4 bottom-0 flex z-50 bg-[linear-gradient(to_top,rgba(0,0,0,.5)_90%,rgba(0,0,0,0)_100%)]">
                <div className="flex flex-col w-full">
                  <div className="w-full flex flex-col gap-3 items-center justify-start">
                    {castingContentArray[currentIndex].link?.type ===
                    LinkType.INSTAGRAM ? (
                      <a
                        href={`https://www.instagram.com/${castingContentArray[currentIndex].link?.url}`}
                        rel="noreferrer"
                        target="_blank"
                        className="w-full flex gap-4 items-center justify-between"
                      >
                        <div className="w-full flex gap-2 items-center justify-between">
                          <div className="w-full flex gap-2 items-center">
                            <img
                              src={InstagramIcon}
                              alt="UpSeat Logo"
                              className="w-10"
                              draggable={false}
                            />
                            <div className="flex flex-col gap-1">
                              <p className="text-sm font-bold">
                                {castingContentArray[currentIndex].title}
                              </p>
                              {castingContentArray[currentIndex].link?.url && (
                                <p className="text-[12px] lowercase">
                                  {`@` +
                                    castingContentArray[currentIndex].link?.url}
                                </p>
                              )}
                            </div>
                          </div>
                          <ExternalLink size={24} color="#F4FF68" />
                        </div>
                      </a>
                    ) : (
                      <p className="w-full font-bold">
                        {castingContentArray[currentIndex].title}
                      </p>
                    )}

                    {Object.keys(castingContentArray[currentIndex].extra_params)
                      .length > 0 && (
                      <>
                        {Object.entries(
                          castingContentArray[currentIndex].extra_params,
                        ).map(([key, value]) => (
                          <p key={key} className="w-full text-sm font-bold">
                            {`${key}: `}
                            <span className="font-normal">
                              {value as string}
                            </span>
                          </p>
                        ))}
                      </>
                    )}

                    <ExpandableText
                      text={castingContentArray[currentIndex].description}
                      isExpanded={isExpanded}
                      setIsExpanded={setIsExpanded}
                      limit={150}
                    />
                  </div>

                  <div className="relative flex flex-col items-center bg-transparent pb-4 pt-6">
                    <div className="w-full flex items-center gap-2">
                      {castingContentArray.map(
                        (_content: any, index: number) => (
                          <SeekBar
                            key={index}
                            currentIndex={currentIndex}
                            duration={duration / 1000}
                            currentTime={seekBarsProgress[index]}
                            isActive={index === currentIndex}
                            handleSkipContent={handleSkipContent}
                          />
                        ),
                      )}
                      <style>{`
              input[type='range']::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 0;
                height: 0;
              }
              input[type='range']::-moz-range-thumb {
                width: 0;
                height: 0;
              }
            `}</style>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </motion.div>
        </section>
      )}
    </AnimatePresence>
  )
}
