import { ChannelItem, MainContent } from '@/types/preshow'
import { create } from 'zustand'

// Define the store's state
type FullScreenContentState = {
  fullScreenIndex: number
  mainContent: MainContent
  fullScreenChannel: ChannelItem[]
  currentEvent: ChannelItem | null
  isMeetTheCast: boolean
  setIsMeetTheCast: (value: boolean) => void
  setFullScreenIndex: (value: number) => void
  setMainContent: (value: MainContent) => void
  skipToNextContent: () => void
  skipToPrevContent: () => void
  setEventInfo: (value: ChannelItem) => void
  setFullScreenChannel: (value: ChannelItem[]) => void
  setCurrentEvent: (value: ChannelItem | undefined) => void
}

// Create the store
export const useFullScreenContentStore = create<FullScreenContentState>(
  (set) => ({
    fullScreenIndex: 0,
    mainContent: {
      event_name: '',
      video_title: '',
      video_description: '',
      badge: '',
      video_url: '',
      link: undefined,
    },
    fullScreenChannel: [] as ChannelItem[], // initial state
    currentEvent: null,
    isMeetTheCast: false,
    setIsMeetTheCast: (value) => set({ isMeetTheCast: value }),
    setFullScreenIndex: (value) => set({ fullScreenIndex: value }),
    setMainContent: (value) => set({ mainContent: value }),
    skipToNextContent: () => {},
    skipToPrevContent: () => {},
    setEventInfo: (value) => set({ currentEvent: value }),
    setFullScreenChannel: (value) => set({ fullScreenChannel: value }),
    setCurrentEvent: (value) => set({ currentEvent: value }),
  }),
)
