import UpSeatLogo from '@/assets/logos/upseat_logo.svg'
import { TileItem, TileType } from '@/types/preshow'
import { MoveLeft, MoveRight } from 'lucide-react'
import { getTileIcon, useActionPerTileType } from './utils'

interface ContentTileProps {
  event: {
    event_name: string
    badge: string
  }
  tile: TileItem
  secondaryColor: boolean
  buttonOnLeft: boolean
}

export const ContentTile = ({
  tile,
  secondaryColor,
  buttonOnLeft,
}: ContentTileProps) => {
  const handleTileAction = useActionPerTileType()

  return (
    <div
      data-testid="content-tile"
      className={`h-[22.25vh] w-full px-2 flex cursor-pointer ${buttonOnLeft ? 'flex-row' : 'flex-row-reverse'}`}
      onClick={() => {
        handleTileAction(tile)
      }}
    >
      <div
        className={`w-[50%] h-full flex flex-col gap-3 justify-center items-center ${
          secondaryColor
            ? 'bg-[linear-gradient(223.42deg,_#083382_15.3%,_#14CBC5_119.84%)]'
            : 'bg-[linear-gradient(230.78deg,#6D10D0_50.49%,#BF3ED9_109.42%)]'
        } ${!buttonOnLeft ? 'rounded-[0_12px_12px_0]' : 'rounded-[12px_0_0_12px]'}

        `}
      >
        <span className="relative bg-yellow shadow-[6px_6px_8px_0px_rgba(25,22,44,0.3)] rounded-full px-2 py-2 [&>svg]:relative">
          {getTileIcon(tile.title)}
        </span>
        <p
          className={`text-yellow lowercase font-bold justify-center text-center flex-wrap text-sm flex items-center gap-1 ${buttonOnLeft ? 'flex-row' : 'flex-row-reverse'}`}
        >
          {tile.title}
          {tile.tile_type !== TileType.TRIGGER ? (
            buttonOnLeft ? (
              <MoveRight size={16} color="yellow" />
            ) : (
              <MoveLeft size={16} color="yellow" />
            )
          ) : null}
        </p>
      </div>

      <div
        className={`w-[50%] h-full bg-purple2/40 ${
          !buttonOnLeft ? 'rounded-[12px_0_0_12px]' : 'rounded-[0_12px_12px_0]'
        }`}
      >
        <div className="w-full h-full flex justify-center items-center">
          <img
            src={tile.preview ?? UpSeatLogo}
            alt={tile.preview ? tile.title : 'up-logo'}
            className={`${tile.preview ? `w-full h-full object-cover ${buttonOnLeft ? 'rounded-[0_12px_12px_0]' : 'rounded-[12px_0_0_12px]'}` : 'w-8'}`}
          />
        </div>
      </div>
    </div>
  )
}
