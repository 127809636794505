import { requestWithRetry } from '../axios'

export const getUser = async () => {
  try {
    const response = await requestWithRetry('upauth/details', 'get')
    return response?.data
  } catch (error: any) {
    // Handle error as per your requirement
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    return error.response?.data
  }
}
