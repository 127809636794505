import { TileItem } from '@/types/preshow'
import { getTileIcon, useActionPerTileType } from './utils'

interface ContentLongTileProps {
  tile: TileItem
  secondaryColor: boolean
}

export const ContentLongTile = ({
  tile,
  secondaryColor,
}: ContentLongTileProps) => {
  const handleTileAction = useActionPerTileType()

  return (
    <div
      className={`h-[9.5vh] w-full px-2 flex`}
      onClick={() => {
        handleTileAction(tile)
      }}
    >
      <div
        className={`w-full h-full flex flex-col gap-3 justify-center items-center rounded-[12px] ${
          secondaryColor
            ? 'bg-[linear-gradient(228.32deg,#2310D0_19.15%,#3E7FD9_101.74%)]'
            : 'bg-[linear-gradient(228.32deg,_#2310D0_19.15%,_#3E7FD9_101.74%)]'
        }`}
      >
        <p
          className={`text-yellow lowercase font-bold justify-center text-center flex-wrap text-sm flex items-center gap-2`}
        >
          <span className="relative bg-yellow shadow-[6px_6px_8px_0px_rgba(25,22,44,0.3)] rounded-full px-2 py-2 [&>svg]:relative">
            {getTileIcon(tile.title)}
          </span>
          {tile.title}
        </p>
      </div>
    </div>
  )
}
