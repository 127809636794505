import { useCallback, useEffect, useState } from 'react'

import { useAppStore } from '@/context/useAppStore'
import { useNavigate } from 'react-router-dom'

const NetworkStatus = () => {
  const [, setIsOnline] = useState(navigator.onLine)
  const navigate = useNavigate()
  const { setAppStore } = useAppStore()

  const updateOnlineStatus = useCallback(() => {
    setIsOnline(navigator.onLine)
    if (navigator.onLine) {
      navigate('/tickets')
    } else {
      setAppStore({
        errors: { network: true, server: false },
      })
    }
  }, [navigate, setAppStore])

  useEffect(() => {
    window.addEventListener('online', updateOnlineStatus)
    window.addEventListener('offline', updateOnlineStatus)

    return () => {
      window.removeEventListener('online', updateOnlineStatus)
      window.removeEventListener('offline', updateOnlineStatus)
    }
  }, [updateOnlineStatus])

  return null
}

export default NetworkStatus
