import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Arrow } from '@/assets/Arrow'
import { ExpandableText } from '@/components/upseat-ui/contentChannel/ExpandableText'
import { PlayIcon } from '@/assets/PlayIcon'
import { SeekBar } from '@/components/upseat-ui/contentChannel/SeekBar'
import { Skeleton } from '@/components/ui/skeleton'
import { VolumeOff } from '@/assets/volumeOff'
import { VolumeOn } from '@/assets/volumeOn'
import { useFullScreenContentStore } from '@/context/useFullScreenContentStore'
import useScreenSize from '@/hooks/useScreenSize'
import { useSwipeDirection } from '@/hooks/useSwipeDirection'
import { useUserStore } from '@/context/useUserStore'
import { ArrowUpFromDot } from 'lucide-react'
import { useMeetTheCastStore } from '@/context/useMeetTheCastStore'
import {
  useContentChannelStore,
  VIEW_STATES,
} from '@/context/useContentChannelStore'
import { LogoFlip } from '@/components/upseat-ui/contentChannel/logoFlipper'

export const FullScreen = () => {
  const {
    mainContent,
    fullScreenChannel,
    currentEvent,
    fullScreenIndex,
    setCurrentEvent,
    setFullScreenIndex,
  } = useFullScreenContentStore()
  const { casting } = useMeetTheCastStore()
  const { setScrollPosition, setBlockSwipeY, setView } =
    useContentChannelStore()
  const navigate = useNavigate()
  const { user } = useUserStore()
  const screenSize = useScreenSize()
  const videoRef = useRef<HTMLVideoElement>(null)
  const { swipeDirection, resetSwipeDirection } = useSwipeDirection()
  const [isMuted, setIsMuted] = useState(true)
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [contentLoaded, setContentLoaded] = useState(false)
  const [badgeLoaded, setBadgeLoaded] = useState(false)
  const [userGuide, setUserGuide] = useState(fullScreenChannel.length > 1)
  const [isExpanded, setIsExpanded] = useState(false)
  const spring = useMemo(() => ({ mass: 5, tension: 500, friction: 80 }), [])

  useEffect(() => {
    setBlockSwipeY(false)
    if (!fullScreenChannel.length) {
      setView(VIEW_STATES.MAIN_CONTENT)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCurrentEvent(fullScreenChannel[fullScreenIndex])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFullScreenIndex])

  useEffect(() => {
    contentLoaded && !userGuide && setIsPlaying(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentLoaded])

  useEffect(() => {
    setIsPlaying(!isExpanded) // Control playback using `isPlaying`
  }, [isExpanded])

  useEffect(() => {
    const video = videoRef.current
    if (!video) return

    if (isPlaying) {
      video.muted = isMuted // Ensure muted state is applied
      video.play().catch(() => {
        setIsPlaying(false) // Handle play error gracefully
      })
    } else {
      video.pause()
    }
  }, [isPlaying, isMuted])

  useEffect(() => {
    if (swipeDirection) {
      handleSkipContent(swipeDirection === 'up')
      resetSwipeDirection()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swipeDirection])

  useEffect(() => {
    setTimeout(() => {
      setUserGuide(false)
    }, 1000)
  }, [fullScreenChannel])

  type Direction = 'next' | 'prev'
  const navigateContent = (direction: Direction) => {
    if (fullScreenChannel.length <= 1) return
    let newIndex
    if (direction === 'next') {
      newIndex = (fullScreenIndex + 1) % fullScreenChannel.length
    } else {
      newIndex =
        fullScreenIndex - 1 < 0
          ? fullScreenChannel.length - 1
          : fullScreenIndex - 1
    }

    setFullScreenIndex(newIndex)
    setCurrentEvent(fullScreenChannel[newIndex])

    // setting meet the cast content in the last position of the fullScreenChannel
    if (
      casting?.content &&
      ((newIndex === 1 && direction === 'next') ||
        (newIndex === 0 && direction === 'prev'))
    ) {
      setView(VIEW_STATES.MEET_THE_CAST)
    }
  }

  const handleSkipContent = (next?: boolean) => {
    next ? navigateContent('next') : navigateContent('prev')
    setCurrentTime(0)
  }

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime)
    }
  }

  const handleLoadedMetadata = () => {
    setContentLoaded(true)
    if (videoRef.current) {
      setDuration(videoRef.current.duration)
    }
  }

  const togglePlayPause = () => {
    setIsPlaying((prev) => !prev) // Toggle playback
  }

  return (
    <AnimatePresence>
      <motion.div
        id="content-full-screen"
        className={`absolute m-0 w-full h-[100svh] flex flex-col justify-center bg-[black]`}
      >
        {!!user && (
          <div
            className="absolute z-[500] cursor-pointer top-0 right-0 h-8 w-8 m-4"
            onClick={() => navigate('/tickets', { replace: true })}
          >
            <motion.div
              transition={spring}
              onClick={() => {
                setScrollPosition(0)
                setView(VIEW_STATES.MAIN_CONTENT)
                navigate('/tickets')
              }}
              className="w-full h-full flex items-center justify-center"
            >
              <LogoFlip flipInterval={3000} />
            </motion.div>
          </div>
        )}

        <div
          data-testid="back-button"
          className="absolute z-[500] cursor-pointer top-0 left-0 pr-6"
          onClick={() => {
            setFullScreenIndex(0)
            setView(VIEW_STATES.MAIN_CONTENT)
          }}
        >
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="relative m-4 z-50 bg-purple1 text-white rounded-full w-8 h-8 flex items-center justify-center"
          >
            <Arrow />
          </motion.span>
        </div>

        {fullScreenChannel.length > 1 && (
          <motion.div
            className="absolute w-full h-full top-0 flex flex-col items-center justify-center gap-2 text-white z-[200] pointer-events-none"
            initial={{ opacity: 0 }}
            animate={{ opacity: userGuide ? 1 : 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
          >
            <ArrowUpFromDot size={30} />
            <p className="font-headline">{`swipe to skip`}</p>
          </motion.div>
        )}

        <div
          className={`relative w-full h-[100svh] transition ease-in-out delay-150 ${userGuide ? 'z-100 brightness-50' : 'z-0 brightness-100'}`}
        >
          {contentLoaded && !isPlaying && !userGuide && (
            <div
              className={`absolute top-0 ${screenSize.width > 1024 ? 'w-[33vw]' : 'w-[100vw]'} h-full flex items-center justify-center`}
            >
              <div
                data-testid="play-button"
                className="[&>svg]:relative [&>svg]:left-[1px] cursor-pointer z-[100] bg-white/50 rounded-full w-16 h-16 flex items-center justify-center"
                onClick={() => setIsPlaying(true)}
              >
                <PlayIcon />
              </div>
            </div>
          )}

          <motion.video
            initial={{ opacity: 0 }}
            animate={{ opacity: contentLoaded ? 1 : 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            ref={videoRef}
            role="video"
            controls={false}
            autoPlay={isPlaying}
            loop
            muted={isMuted}
            playsInline={true}
            className={`absolute z-50 top-0 w-full h-[100svh] object-cover ${contentLoaded ? '' : 'hidden'}`}
            onLoadedMetadata={handleLoadedMetadata}
            onTimeUpdate={handleTimeUpdate}
            onClick={togglePlayPause}
            src={currentEvent?.content}
          />

          <div className="absolute inset-0 z-20 pointer-events-none" />

          <motion.div
            className="w-full items-center justify-between absolute pt-24 pb-2 p-6 bottom-0 flex z-50 bg-[linear-gradient(to_top,rgba(0,0,0,.6)_50%,rgba(0,0,0,0)_100%)]"
            initial={{ opacity: 0 }}
            animate={{ opacity: !userGuide ? 1 : 0 }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0, transition: { duration: 0 } }}
          >
            <div className="flex flex-col w-full">
              <div className="flex gap-2 z-50 items-start justify-between w-full">
                <div className="flex gap-2 items-center">
                  <img
                    src={mainContent.badge}
                    alt="preshowOne"
                    draggable={false}
                    className={`w-8 h-8 rounded-[4px] ${badgeLoaded ? '' : 'hidden'}`}
                    onLoad={() => setBadgeLoaded(true)}
                  />
                  {!badgeLoaded && (
                    <Skeleton className="w-8 h-8 rounded-[4px] bg-purple" />
                  )}
                  <p className="font-bold">
                    {mainContent.video_title ?? mainContent.event_name}
                  </p>
                </div>

                <div
                  data-testid="mute-button"
                  className="cursor-pointer top-0 bg-white/50 rounded-full w-8 h-8 flex items-center justify-center"
                  onClick={() => setIsMuted(!isMuted)}
                >
                  {isMuted ? (
                    <span data-testid="volume-off">
                      <VolumeOff />
                    </span>
                  ) : (
                    <span data-testid="volume-on">
                      <VolumeOn />
                    </span>
                  )}
                </div>
              </div>

              {currentEvent?.description?.length && (
                <div className="pt-3 z-50">
                  <ExpandableText
                    text={currentEvent?.description as string}
                    limit={100}
                    isExpanded={isExpanded}
                    setIsExpanded={setIsExpanded}
                  />
                </div>
              )}

              {/* Timeline (Seek Bar) */}
              {contentLoaded && (
                <div className="relative flex flex-col items-center z-50 bg-transparent py-4">
                  <SeekBar
                    currentIndex={0}
                    videoRef={videoRef}
                    duration={duration}
                    currentTime={currentTime}
                    setCurrentTime={setCurrentTime}
                    isActive
                  />

                  {/* Custom styles to hide the default thumb */}
                  <style>{`
              input[type='range']::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 0;
                height: 0;
              }
              input[type='range']::-moz-range-thumb {
                width: 0;
                height: 0;
              }
            `}</style>
                </div>
              )}
            </div>
          </motion.div>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}
