import { useEffect } from 'react'

const CHECK_INTERVAL = 60000 // 60 seconds

export function useVersionChecker(
  setUpdateAvailable: (available: boolean) => void,
) {
  const checkVersion = async () => {
    console.log('Checking for new version...')
    try {
      const response = await fetch('/', { cache: 'no-store' })
      const etag = response.headers.get('ETag') // Get versioning info from headers
      console.log('Current version:', etag)
      if (etag && etag !== localStorage.getItem('app-version')) {
        setUpdateAvailable(true)
        localStorage.setItem('app-version', etag)
      }
    } catch (err) {
      console.log('Version check failed:', err)
    }
  }

  useEffect(() => {
    checkVersion() // Run on first load

    const interval = setInterval(checkVersion, CHECK_INTERVAL)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
