import { useTicketsStore } from '@/context/useTicketsStore'
import { getWallet } from '@/services/Wallet/getWallet'
import { Booking, Order } from '@/types/booking'
import { useState, useRef, useCallback } from 'react'

export const useUserTickets = () => {
  const { setWallet, setWalletOrders } = useTicketsStore()
  const [isLoading, setLoadingTickets] = useState<boolean>(true)
  const [syncLoading, setSyncLoading] = useState<boolean>(false)
  const getWalletCallCounter = useRef<number>(0)

  const loadUserTickets = useCallback(async () => {
    // Check if the URL ends with '/tickets'
    if (!window.location.pathname.endsWith('/tickets')) {
      setLoadingTickets(false)
      return
    }

    try {
      const res = await getWallet()

      if (!res.length) {
        setLoadingTickets(false)
        return
      }

      setWallet(res)
      setWalletOrders(
        res.flatMap((booking: Booking) => booking.orders) as Order[],
      )
    } catch (error: any) {
      console.log('Error fetching wallet:', error)
      if (error.statis === 401) {
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
      }
    } finally {
      setSyncLoading(false)
      setLoadingTickets(false) // Ensure loading state is updated on error
    }

    // Increment wallet call counter
    getWalletCallCounter.current += 1

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setWallet])

  return {
    loadUserTickets,
    isLoading,
    syncLoading,
    setSyncLoading,
  }
}
