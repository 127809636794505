import { useEffect, useRef, useState } from 'react'
import { ChannelItem, TileItem, TileType } from '@/types/preshow'
import { GridVideo } from './GridVideo'
import { getUniqueId, isVideo } from '@/libs/utils'
import { motion } from 'framer-motion'
import { useFullScreenContentStore } from '@/context/useFullScreenContentStore'
import {
  useContentChannelStore,
  VIEW_STATES,
} from '@/context/useContentChannelStore'
import { ContentTile } from './Tiles/ContentTile'
import { ContentLongTile } from './Tiles/ContentLongTile'
import useEventStore from '@/context/useEventStore'
import { useTicketsStore } from '@/context/useTicketsStore'
import { useSeatMap } from '@/hooks/useSeatMap'
import { useSearchParams } from 'react-router-dom'
import { Order } from '@/types/booking'

interface ContentGridProps {
  gridContent: ChannelItem[]
  badge: string
  event_name: string
}

export const ContentGrid = ({
  gridContent,
  badge,
  event_name,
}: ContentGridProps) => {
  const { walletOrders } = useTicketsStore()
  const { channelContent, setView, setScrollPosition } =
    useContentChannelStore()
  const { fullScreenChannel, setFullScreenIndex, setMainContent } =
    useFullScreenContentStore()
  const [videoResolution, setVideoResolution] = useState({
    width: 0,
    height: 0,
  })
  const [gridVideosLoaded, setGridVideosLoaded] = useState(false)
  const { mapSeats } = useEventStore()
  const { getSeatMap } = useSeatMap()
  const [searchParams] = useSearchParams()
  const order_uid = searchParams.get('order')
  const [upgradeAvailable, setUpgradeAvailable] = useState(false)
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    const loadSeatMap = async () => {
      if (!order_uid || !walletOrders) return

      const currentOrder = walletOrders.find(
        (order) => order.uid === order_uid,
      ) as Order

      if (currentOrder && currentOrder.state.upgrade.available) {
        setUpgradeAvailable(true)
        await getSeatMap(currentOrder)
      }
    }

    loadSeatMap()
  }, [walletOrders, mapSeats.length, getSeatMap, searchParams, order_uid])

  // Handle video metadata load to get resolution
  useEffect(() => {
    const videoElement = videoRef.current

    const handleLoadedMetadata = () => {
      if (videoElement) {
        setVideoResolution({
          width: videoElement.videoWidth,
          height: videoElement.videoHeight,
        })
      }
    }

    if (videoElement) {
      videoElement.addEventListener('loadedmetadata', handleLoadedMetadata)
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata)
      }
    }
  }, [])

  // Filter out trigger tiles if no seats are available
  const tiles = channelContent.tiles.filter(
    (tile: TileItem) => upgradeAvailable || tile.tile_type !== TileType.TRIGGER,
  )

  const videos = gridContent.filter((cont) => isVideo(cont.content))

  // Handle video click to go to full screen mode
  const handleVideoClick = (channelItem: ChannelItem) => {
    setScrollPosition(window.scrollY)
    const index = fullScreenChannel.findIndex(
      (c: { y_axis: number }) => channelItem.y_axis === c?.y_axis,
    )
    setFullScreenIndex(index)
    setMainContent({
      event_name: event_name,
      badge: badge,
      video_title: channelItem.title,
      video_url: channelItem.content,
      video_description: channelItem.description as string,
    })
    setView(VIEW_STATES.FULL_SCREEN)
  }

  // Render content in the order: 2 tiles, 1 video, 1 long tile, repeat
  const renderContent = () => {
    const content = []
    let tileIndex = 0
    let videoIndex = 0

    while (tileIndex < tiles.length || videoIndex < videos.length) {
      // Handle trigger tiles - render one tile then force a video next
      if (
        tileIndex < tiles.length &&
        tiles[tileIndex].tile_type === TileType.TRIGGER
      ) {
        // Render just one tile if it's a trigger
        content.push(
          <div
            key={`tile-${tileIndex}`}
            className="flex w-full flex-col gap-2 py-2"
          >
            <div key={getUniqueId()} className="w-full h-auto">
              <ContentTile
                event={channelContent.assets}
                tile={tiles[tileIndex]}
                secondaryColor={tiles[tileIndex].y_axis > 2}
                buttonOnLeft={tiles[tileIndex].y_axis % 2 === 0}
              />
            </div>
          </div>,
        )
        tileIndex++

        // Force a video next if available
        if (videoIndex < videos.length) {
          const channelItem = videos[videoIndex]
          content.push(
            <motion.div
              key={`video-${videoIndex}`}
              className="relative flex flex-col"
            >
              <GridVideo
                channelItem={channelItem}
                videoResolution={videoResolution}
                setCurrentGridVideo={() => handleVideoClick(channelItem)}
                setGridVideosLoaded={setGridVideosLoaded}
              />
            </motion.div>,
          )
          videoIndex++
        }
        continue // Skip the rest of this iteration
      }

      // Render 2 non-trigger tiles
      if (tileIndex < tiles.length) {
        // Make sure we don't include a trigger tile in the pair
        let tilePair = []
        if (
          tileIndex < tiles.length &&
          tiles[tileIndex].tile_type !== TileType.TRIGGER
        ) {
          tilePair.push(tiles[tileIndex])
          tileIndex++
        }
        if (
          tileIndex < tiles.length &&
          tiles[tileIndex].tile_type !== TileType.TRIGGER
        ) {
          tilePair.push(tiles[tileIndex])
          tileIndex++
        }

        if (tilePair.length > 0) {
          content.push(
            <div
              key={`tiles-${tileIndex}`}
              className="flex w-full h-auto flex-col gap-2 py-2"
            >
              {tilePair.map((tile: TileItem) => (
                <div key={getUniqueId()} className="w-full h-auto">
                  <ContentTile
                    event={channelContent.assets}
                    tile={tile}
                    secondaryColor={tile.y_axis > 2}
                    buttonOnLeft={tile.y_axis % 2 === 0}
                  />
                </div>
              ))}
            </div>,
          )
        }
      }

      // Render 1 video (if we haven't already forced one after a trigger)
      if (videoIndex < videos.length) {
        const channelItem = videos[videoIndex]
        content.push(
          <motion.div
            key={`video-${videoIndex}`}
            className="relative flex flex-col"
          >
            <GridVideo
              channelItem={channelItem}
              videoResolution={videoResolution}
              setCurrentGridVideo={() => handleVideoClick(channelItem)}
              setGridVideosLoaded={setGridVideosLoaded}
            />
          </motion.div>,
        )
        videoIndex++
      }

      // Render 1 long tile and follow it with a video
      if (
        tileIndex < tiles.length &&
        tiles[tileIndex].tile_type === TileType.TRIGGER
      ) {
        const tile = tiles[tileIndex]
        content.push(
          <div key={getUniqueId()} className="w-full h-full py-2">
            <ContentLongTile tile={tile} secondaryColor={false} />
          </div>,
        )
        tileIndex++

        // Force a video after the long tile if available
        if (videoIndex < videos.length) {
          const channelItem = videos[videoIndex]
          content.push(
            <motion.div
              key={`video-${videoIndex}`}
              className="relative flex flex-col"
            >
              <GridVideo
                channelItem={channelItem}
                videoResolution={videoResolution}
                setCurrentGridVideo={() => handleVideoClick(channelItem)}
                setGridVideosLoaded={setGridVideosLoaded}
              />
            </motion.div>,
          )
          videoIndex++
        }
      }
    }

    return content
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: gridVideosLoaded ? 1 : 0 }}
      transition={{ staggerChildren: 0.1 }}
      className="flex flex-wrap flex-col h-full w-full overflow-hidden bg-purple"
    >
      {renderContent()}
    </motion.div>
  )
}
