export interface Ticket {
  uid: string
  plan_name: string
  seat_name: string
  price: string
  barcode: string
  order_uid: string
}

export interface StateFeature {
  available: boolean
  msg: string
  code?: number
}

export interface State {
  upgrade: StateFeature
  refund: StateFeature
  swap: StateFeature
  send: StateFeature
}

export interface Config {
  uid: string
  is_qr: boolean
  payment_enabled: boolean
  basket_enabled: boolean
}

export enum OrderStatus {
  STANDARD = 'standard',
  UPGRADED = 'upgraded',
  SWAPPED = 'swapped',
  UPGRADING = 'upgrading',
  SWAPPING = 'swapping',
  REFUNDED = 'refunded',
  REFUNDING = 'refunding',
}

export interface Order {
  config: Config
  uid: string
  order_id: string
  status: OrderStatus
  state: State
  tickets: Ticket[]
}

interface Channel {
  event_uid: string
  first_content: {
    content: string
    description: string
    x_axis: number
    y_axis: number
    uid: string
  }
}

export interface Booking {
  start_time: Date
  duration: number
  event_name: string
  thumbnail_url: string
  theatre_name: string
  orders: Order[]
  channel: Channel
  config: Config
}
