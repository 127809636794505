import { ArrowLong } from '@/assets/ArrowLong'
import { Button } from '@/components/ui/button'
import { useUserStore } from '@/context/useUserStore'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import { getUser } from '@/services/User/getUser'
import animationStep2 from '@/assets/animations/cookie_animation.json'
import Lottie, { LottieRefCurrentProps } from 'lottie-react'
import { useEffect, useRef, useCallback } from 'react'
import { updateUser } from '@/services/User/updateUser'

export const CookiesConsent = () => {
  const lottieRef = useRef<LottieRefCurrentProps | null>(null)
  const callRouteWithDirection = useCallRouteWithDirection()
  const { setUser } = useUserStore()

  useEffect(() => {
    if (lottieRef.current) {
      lottieRef.current.pause()
      setTimeout(() => {
        lottieRef.current?.play()
      }, 1500)
    }
  }, [])

  const cookieAnimation = () => {
    return (
      <Lottie
        animationData={animationStep2}
        loop={false}
        className="h-9 w-[15%]"
        lottieRef={lottieRef}
        autoplay={false}
      />
    )
  }

  const handleAccept = useCallback(
    async (accepted: boolean) => {
      try {
        await updateUser({ cookies: accepted })
      } catch (error) {
        console.log('Error handling cookie consent:', error)
      }

      try {
        const userResponse = await getUser()

        if (userResponse) {
          setUser({
            uid: userResponse.uid,
            username: userResponse.email.split('@')[0],
            firstname: userResponse.first_name,
            lastname: userResponse.last_name,
            email: userResponse.email,
            phoneNumber: userResponse.phone_number,
            cookies: userResponse.cookies,
            marketing: userResponse.marketing,
          })
        }
        callRouteWithDirection('/tickets', true, 1)
      } catch (error) {
        console.log('Error handling cookie consent:', error)
      }
    },
    [setUser, callRouteWithDirection],
  )

  return (
    <div className="relative w-full flex flex-col gap-3 items-center justify-center">
      <div className="font-bold flex gap-2 items-center text-nowrap">
        {cookieAnimation()}
        {`Cookies you'll actually want`}
      </div>

      <div>
        <p className="text-[12px] leading-4 font-light text-pretty">
          {`We use cookies to personalise your in-app experience.`}
        </p>
        <p className="text-[12px] leading-4 font-light text-pretty">
          {`By clicking "accept" you consent to our`}
          <a
            className="text-purple5 text-[12px]"
            target="_blank"
            rel="noopener noreferrer"
            href="https://upapp.notion.site/Cookie-Policy-5d78be8dbc814d689eadf528a1705b5e"
          >
            {' cookie policy'}
            <span>.</span>
          </a>
        </p>
      </div>

      <div className="relative w-full flex flex-col gap-2 items-center justify-center">
        <Button
          className="w-full text-white py-3 pb-2"
          variant="textOnly"
          data-testid="cookies-no-thanks"
          onClick={() => handleAccept(false)}
        >
          {`No thanks`}
        </Button>
        <Button
          className="w-full py-4"
          data-testid="cookies-accept"
          variant="primaryYellow"
          onClick={() => handleAccept(true)}
        >
          {`Accept`} <ArrowLong />
        </Button>
      </div>
    </div>
  )
}
