export const VolumeOff = () => {
  return (
    <svg
      role="button"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M13.26 3.20534C13.4165 3.09339 13.5997 3.02444 13.7911 3.0054C13.9826 2.98636 14.1758 3.01788 14.3512 3.09682C14.5267 3.17576 14.6785 3.29936 14.7912 3.45527C14.904 3.61119 14.9739 3.79396 14.994 3.98534L15 4.09934V19.7113C15.0001 19.9038 14.9496 20.0929 14.8538 20.2597C14.7579 20.4266 14.6199 20.5654 14.4536 20.6622C14.2874 20.7591 14.0986 20.8106 13.9061 20.8117C13.7137 20.8128 13.5244 20.7633 13.357 20.6683L13.261 20.6063L6.68 15.9053H4C3.49542 15.9055 3.00943 15.7149 2.63945 15.3718C2.26947 15.0288 2.04284 14.5585 2.005 14.0553L2 13.9053V9.90534C2.00009 9.40094 2.19077 8.9152 2.53384 8.54543C2.8769 8.17566 3.34702 7.94917 3.85 7.91134L4 7.90534H6.68L13.26 3.20534ZM17.586 9.07734L19 10.4913L20.414 9.07734C20.5062 8.98183 20.6166 8.90565 20.7386 8.85324C20.8606 8.80083 20.9918 8.77325 21.1246 8.77209C21.2574 8.77094 21.3891 8.79624 21.512 8.84652C21.6348 8.8968 21.7465 8.97106 21.8404 9.06495C21.9343 9.15884 22.0085 9.27049 22.0588 9.39339C22.1091 9.51629 22.1344 9.64797 22.1333 9.78075C22.1321 9.91352 22.1045 10.0447 22.0521 10.1667C21.9997 10.2888 21.9235 10.3991 21.828 10.4913L20.414 11.9053L21.828 13.3193C22.0156 13.5069 22.1211 13.7612 22.1212 14.0265C22.1213 14.2918 22.016 14.5462 21.8285 14.7338C21.641 14.9215 21.3866 15.027 21.1214 15.027C20.8561 15.0271 20.6016 14.9219 20.414 14.7343L19 13.3193L17.586 14.7343C17.3984 14.9219 17.1439 15.0271 16.8786 15.027C16.6134 15.027 16.359 14.9215 16.1715 14.7338C15.984 14.5462 15.8787 14.2918 15.8788 14.0265C15.8789 13.7612 15.9844 13.5069 16.172 13.3193L17.586 11.9053L16.172 10.4913C16.0765 10.3991 16.0003 10.2888 15.9479 10.1667C15.8955 10.0447 15.8679 9.91352 15.8667 9.78075C15.8656 9.64797 15.8909 9.51629 15.9412 9.39339C15.9915 9.27049 16.0657 9.15884 16.1596 9.06495C16.2535 8.97106 16.3652 8.8968 16.488 8.84652C16.6109 8.79624 16.7426 8.77094 16.8754 8.77209C17.0082 8.77325 17.1394 8.80083 17.2614 8.85324C17.3834 8.90565 17.4938 8.98183 17.586 9.07734Z"
        fill="white"
      />
    </svg>
  )
}
