export type User = {
  uid: string
  username?: string
  firstname?: string
  lastname?: string
  email: string
  phoneNumber?: string
  cookies?: boolean
  marketing?: boolean
  emailNotifications?: boolean
}

export type Location = {
  id: string
  name: string
}

export type Currency = {
  name: string
  symbol: string
}

export type Seat = {
  seat_id: string
  location: string
  name: string
  row: string
  number: string
  band_uid: string
  tier: number
  upgrade_price: number
  original_price: number
  discount: number
  saved_price: number
  number_tickets: number
}

export type PriceBand = {
  uid: string
  active_discount: number
  discount_price?: number
  original_price: number
  upgrade_values: {
    upgrade_price: number
    original_price: number
    saved_price: number
    number_tickets: number
    discount_percentage: number
    unit_original_price: number
    unit_upgrade_price: number
  }
}

export type CountryCode = {
  code: string
  acronym: string
  digits: string
}

export enum TicketStates {
  UPGRADE_NOW = 'Upgrade Now',
  UPGRADED = 'Upgraded',
  UPGRADE_TO_BE_AVAILABLE = 'Upgrades Available 1-8pm',
  VIEW_TICKET = 'View Ticket',
}

export type State = {
  code: number
  msg: string
}

export type Config = {
  uid: string
  is_qr: boolean
  payment_enabled: boolean
  basket_enabled: boolean
}
export type ShowData = {
  title: string
  dateFrom: Date
  dateTo: Date
  imageUrl?: string
}

export enum ShowErrorStatus {
  NOT_AVAILABLE = 'not available',
  SOLD_OUT = 'sold out',
  NO_PARTNERSHIP = 'no partnership',
}

export type PricePoint = {
  percentage: number
  seatType: string
}

export type BasketSeat = {
  seat_name: string
  plan_name: string
}

export type BasketType = {
  UPGRADE: 'upgrade'
  SWAP: 'swap'
}

export type Basket = {
  upgrade_uid?: string
  swap_uid?: string
  seats: BasketSeat[]
  uid: string
  expires_utc: Date
  total_price: number
  unit_price: number
  number_tickets: number
}

export enum SectionNames {
  HOME = 'HOME',
  SEARCH = 'SEARCH',
  TICKETS = 'TICKETS',
  ACCOUNT = 'ACCOUNT',
}

export type Section = {
  index: number
  name: SectionNames
}

export const sectionMap: Record<number, SectionNames> = {
  0: SectionNames.HOME,
  1: SectionNames.SEARCH,
  2: SectionNames.TICKETS,
  3: SectionNames.ACCOUNT,
}

export interface SeatMapTestProps {
  selectedSeats: Seat[]
  setSelectedSeats: (seats: Seat[]) => void
  priceBands: PriceBand[]
  mapSeats: Seat[]
}
