import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useEffect, useState } from 'react'

import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import LoadingIndicator from '@/components/upseat-ui/seatMap/LoadingIndicator'
import { LockerIcon } from '@/assets/LockerIcon'
import { StripePaymentElementOptions } from '@stripe/stripe-js'
import { useCartStore } from '@/context/useCartStore'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import { useBasketStore } from '@/context/useBasketStore'
import useEventStore from '@/context/useEventStore'
import { useUserTickets } from '@/hooks/useUserTickets'
import { toast } from '@/hooks/useToast'

export default function CheckoutForm() {
  const { removeBasket } = useBasketStore()
  const { loadUserTickets } = useUserTickets()
  const { setSelectedSeats, setMapSeats } = useEventStore()
  const callRouteWithDirection = useCallRouteWithDirection()
  const { loadingPayment, setLoadingPayment } = useCartStore()
  const stripe = useStripe()
  const elements = useElements()
  const [message, setMessage] = useState<string | null>(null)

  const [termsAccepted, setTermsAccepted] = useState<boolean>(false)
  const [isPaymentElementLoaded, setIsPaymentElementLoaded] = useState(false)

  useEffect(() => {
    if (!stripe) {
      return
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    )

    if (!clientSecret) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case 'succeeded':
          setMessage('Payment succeeded!')
          break
        case 'processing':
          setMessage('Your payment is processing.')
          break
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.')
          break
        default:
          setMessage('Something went wrong.')
          break
      }
    })
  }, [stripe])

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setMessage(null)

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setLoadingPayment(true)

    stripe
      .confirmPayment({
        elements,
        redirect: 'if_required',
      })
      .then(async (res) => {
        if (res.paymentIntent?.status === 'succeeded') {
          await removeBasket()
          await setMapSeats([])
          await setSelectedSeats([])
          await loadUserTickets()
          toast({
            title: 'Payment successful!',
            description: 'Your ticket will be updated shortly.',
          })

          callRouteWithDirection('/tickets', true, 1)
        } else {
          setMessage('Error confirming payment. Please try again.')
        }
      })
      .catch((error) => {
        if (error.type === 'card_error' || error.type === 'validation_error') {
          setMessage(error.message as string)
        } else {
          setMessage('An unexpected error occurred.')
        }
      })
      .finally(() => {
        setLoadingPayment(false)
      })
  }

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: 'accordion',
    wallets: {
      applePay: 'auto', // 'auto' will render Apple Pay if available
      googlePay: 'auto', // 'auto' will render Google Pay if available
    },
  }

  return (
    <form
      id="payment-form"
      data-testid="payment-form"
      className={'font-body bg-white h-[90svh] [&>AccordionItem]:border-none'}
      onSubmit={handleSubmit}
    >
      <PaymentElement
        id="payment-element"
        className={'mb-2'}
        options={paymentElementOptions}
        onReady={() => setIsPaymentElementLoaded(true)}
        onChange={() => setMessage(null)}
      />

      {message && (
        <p className="text-red text-sm mb-8 text-center">{message}</p>
      )}

      {isPaymentElementLoaded && (
        <>
          <p className="text-sm font-light flex items-start my-4">
            <Checkbox
              id="terms"
              defaultChecked={termsAccepted}
              onClick={() => setTermsAccepted(!termsAccepted)}
            />
            <label
              htmlFor="terms"
              className="text-sm cursor-pointer pl-2 peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              {`I agree to Up's`}
              {` `}
              <a
                className="text-purple3"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.notion.so/upseat/Our-legals-273016cae1d24dd4802f65e788c47631"
              >{`Terms & Conditions`}</a>
              {` and understand that my old tickets are no longer valid.`}
            </label>
          </p>

          {loadingPayment ? (
            <div className="my-8 py-4">
              <LoadingIndicator dark />
            </div>
          ) : (
            <Button
              variant={'primaryDark'}
              disabled={
                loadingPayment || !stripe || !elements || !termsAccepted
              }
              id="submit"
              className="w-full py-4 px-6 rounded-lg"
            >
              <span
                id="button-text"
                className={`flex gap-2 items-center ${termsAccepted && 'text-yellow'}`}
              >
                {'Pay securely'}
                {termsAccepted && <LockerIcon />}
              </span>
            </Button>
          )}
          <div className="mb-4">
            <p className="text-[12px] font-light text-center pt-2">{`You must keep your original booking receipt to show at the theatre if requested. `}</p>
          </div>
        </>
      )}
    </form>
  )
}
