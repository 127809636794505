import React, { useEffect, useRef, useState } from 'react'

import { ExpandableText } from './ExpandableText'
import { PlayIcon } from '@/assets/PlayIcon'
import { VolumeOff } from '@/assets/volumeOff'
import { VolumeOn } from '@/assets/volumeOn'
import { ChannelItem } from '@/types/preshow'
import { useContentChannelStore } from '@/context/useContentChannelStore'
import { SeekBar } from './SeekBar'

interface GridVideoProps {
  channelItem: ChannelItem
  videoResolution: {
    width: number
    height: number
  }
  setCurrentGridVideo: () => void
  setGridVideosLoaded: (value: boolean) => void
}

export const GridVideo: React.FC<GridVideoProps> = ({
  channelItem,
  setCurrentGridVideo,
  setGridVideosLoaded,
}) => {
  const { channelContent } = useContentChannelStore()
  const videoRef = useRef<HTMLVideoElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [isMuted, setIsMuted] = useState(true)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [, setPoster] = useState<string>('')
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)

  // Use canvas to extract the first frame of the video
  useEffect(() => {
    const video = videoRef.current
    if (!video) return

    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    if (ctx) {
      // Ensure the canvas matches the video resolution
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight

      video.addEventListener('loadeddata', () => {
        // Draw the first frame
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
        setPoster(canvas.toDataURL()) // Set the poster as the first frame image
      })
    }
  }, [channelItem.content])

  // Ensure video metadata is preloaded on page load
  useEffect(() => {
    const video = videoRef.current
    if (video) {
      video.load() // Preload the video metadata
    }
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        const video = videoRef.current
        if (!video) return

        if (entry.isIntersecting) {
          video
            .play()
            .catch((error) => console.log('Video play failed:', error))
          setIsPlaying(true)
          setIsMuted(false)
        } else {
          video.pause()
          setIsPlaying(false)
          setIsMuted(true) // Auto mute when out of view
        }
      },
      { threshold: 1 }, // Only play when 100% in view
    )

    const container = containerRef.current
    if (container) observer.observe(container)

    return () => {
      if (container) observer.unobserve(container)
      observer.disconnect()
    }
  }, [])

  // Handle metadata load and video readiness
  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      setDuration(videoRef.current.duration)
    }
  }

  const handleTimeUpdate = () => {
    if (videoRef.current) setCurrentTime(videoRef.current.currentTime)
  }

  return (
    <div
      ref={containerRef}
      className="relative w-full h-full flex justify-center items-center cursor-pointer"
    >
      <video
        preload="metadata"
        ref={videoRef}
        loop
        controls={false}
        muted={isMuted}
        playsInline
        className="w-[100vw] h-full max-h-[50vh] object-cover transition-opacity duration-500"
        onCanPlay={() => setGridVideosLoaded(true)}
        onCanPlayThrough={() => setGridVideosLoaded(true)}
        onClick={() => {
          setCurrentGridVideo()
        }}
        onLoadedMetadata={handleLoadedMetadata}
        onTimeUpdate={handleTimeUpdate}
      >
        <source src={channelItem.content} />
      </video>

      {!isPlaying && (
        <div className="absolute">
          <div
            className="[&>svg]:relative [&>svg]:left-[1px] cursor-pointer z-50 bg-white/50 rounded-full w-16 h-16 flex items-center justify-center"
            onClick={() => videoRef.current?.play()}
          >
            <PlayIcon />
          </div>
        </div>
      )}

      <div
        className={`absolute bottom-[-1px] flex w-full items-center justify-between p-4 pt-12 bg-[linear-gradient(to_top,rgba(0,0,0,.7)_50%,rgba(0,0,0,0)_100%)]`}
      >
        <div className="w-full flex flex-col gap-1">
          <div className="flex w-full justify-between">
            <div className={`flex gap-2 w-[90%]`}>
              <img
                src={channelContent.assets.badge}
                alt={channelContent.assets.event_name}
                className="w-8 h-8 rounded-[4px]"
                draggable={false}
              />
              <div className="flex flex-col justify-center w-full">
                <p className="font-bold">{channelContent.assets.event_name}</p>
              </div>
            </div>

            <div
              className="cursor-pointer z-50 bg-white/50 rounded-full w-8 h-8 flex items-center justify-center"
              onClick={() => setIsMuted(!isMuted)}
            >
              {isMuted ? <VolumeOff /> : <VolumeOn />}
            </div>
          </div>
          {duration > 0 && (
            <div className="w-full relative flex flex-col items-center bg-transparent py-2">
              <SeekBar
                videoRef={videoRef}
                currentIndex={0}
                duration={duration}
                currentTime={currentTime}
                setCurrentTime={setCurrentTime}
                ref={videoRef}
                isActive
              />
              {/* Custom styles to hide the default thumb */}
              <style>
                {`
                        input[type='range']::-webkit-slider-thumb {
                          -webkit-appearance: none;
                          appearance: none;
                          width: 0;
                          height: 0;
                        }
                        input[type='range']::-moz-range-thumb {
                          width: 0;
                          height: 0;
                        `}
              </style>
            </div>
          )}
          {channelItem.description && (
            <div className="w-full pr-4">
              <ExpandableText
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
                text={channelItem.description}
                limit={60}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
