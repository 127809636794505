import React, { ReactNode, forwardRef } from 'react'

import useScreenSize from '@/hooks/useScreenSize'

type NavLayoutProps = {
  children: ReactNode
  overflowHidden?: boolean
}

const NavLayout = forwardRef<HTMLDivElement, NavLayoutProps>(
  ({ children, overflowHidden }, ref) => {
    const screenSize = useScreenSize()

    return (
      <div
        id="nav-layout"
        ref={ref}
        className={`app-view bg-purple
      ${screenSize.width > 1280 && 'pl-[35vw] pr-[35vw] overflow-auto flex flex-col justify-between'}
      ${overflowHidden && 'overflow-hidden'}
      `}
      >
        <main className={`relative`}>{children}</main>
      </div>
    )
  },
)
// h-lvh
export default NavLayout
