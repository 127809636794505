import { UpSeatLogo } from '@/assets/logos/upseat_logo'
import { useAppStore } from '@/context/useAppStore'
import { useUserStore } from '@/context/useUserStore'
import useScreenSize from '@/hooks/useScreenSize'

interface NavigationHeaderProps {
  activeItem: number
  setActiveItem: (item: number) => void
  searchingSeats?: boolean
}

export const NavigationHeader = ({ searchingSeats }: NavigationHeaderProps) => {
  const screenSize = useScreenSize()
  const { user } = useUserStore()
  const { ui, setAppStore } = useAppStore()

  return (
    <>
      {user && !searchingSeats && (
        <div
          className={`${screenSize.width > 1280 ? 'w-[400px]' : 'w-full px-6'} flex items-center justify-between dark:bg-transparent backdrop-blur-sm z-[500] pt-3`}
        >
          <div
            id="logo-top-nav-btn"
            className="[&>svg]:w-auto [&>svg]:h-[1.75rem] [&>svg]:relative [&>svg]:top-[2px] py-2 cursor-pointer"
            onClick={() =>
              setAppStore({
                ui: {
                  ...ui,
                  sideMenu: true,
                },
              })
            }
          >
            <UpSeatLogo />
          </div>
        </div>
      )}
    </>
  )
}
