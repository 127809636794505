import { z } from 'zod'

export const signUpValidationSchema = z
  .object({
    firstName: z.string().min(1, { message: 'First Name is required' }),
    lastName: z.string().min(1, { message: 'Last Name is required' }),
    email: z.string().min(1, { message: 'Email is required' }),
    password: z
      .string()
      .min(1, { message: 'Password is required' })
      .regex(
        /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/,
        'Must be 8+ characters with at least one uppercase letter, one digit and one symbol',
      ),
    confirmPassword: z.string().optional(),
    phoneNumber: z
      .string()
      .min(1, { message: 'Phone Number is required' })
      .optional(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Passwords do not match.',
    path: ['confirmPassword'],
  })
  .refine((data) => data.email.includes('@'), {
    message: 'Invalid email address. Please try again.',
    path: ['email'],
  })

export type signUpValidationSchemaType = z.infer<typeof signUpValidationSchema>
