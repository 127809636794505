import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { formatInTimeZone } from 'date-fns-tz'
import { v4 as uuidv4 } from 'uuid'
import { Ticket } from '@/types/booking'
import { toast } from '@/hooks/useToast'
import { transitionEasing, transitionEasingReverse } from '../global-vars'

// Transition presets
export const transition = {
  ease: transitionEasing,
  duration: 0.325,
  delay: 0.325,
}
export const transitionIn = { ease: transitionEasing, duration: 0.125 }
export const transitionOut = { ease: transitionEasingReverse, duration: 0.125 }

// Tailwind class merge helper
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

// Format date with timezone
export const formattedDate = (date: Date) =>
  formatInTimeZone(date, 'Europe/London', 'EEE d MMM, h:mmaaa')

// Format date & time into an object
export function formatDateTime(dateInput: Date) {
  const date = new Date(dateInput)
  if (isNaN(date.getTime())) return 'Invalid Date'

  const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  return {
    date: `${weekdays[date.getDay()]} ${date.getDate()} ${months[date.getMonth()]}, ${date.getFullYear()}`,
    time: date.toTimeString().slice(0, 5), // Extract HH:MM
  }
}

// Format date to 'Weekday DD.MM.YY at HH:MM'
export const formatDateToWeekDayDDMMYY = (dateInput?: Date | string) => {
  if (!dateInput) return 'n/a'
  const date = new Date(dateInput)
  if (isNaN(date.getTime())) return ''

  const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  return `${weekdays[date.getDay()]} ${date.getDate().toString().padStart(2, '0')}.${(
    date.getMonth() + 1
  )
    .toString()
    .padStart(2, '0')}.${date.getFullYear().toString().slice(-2)} at ${date
    .toTimeString()
    .slice(0, 5)}`
}

// Get formatted UTC time in 12-hour format
export const timeString = (date: Date) =>
  new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  })
    .format(date)
    .toLowerCase()

// Get first and last day of the month in UTC
export const getFirstDayOfMonth = (date: Date) =>
  new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1))
    .toISOString()
    .split('T')[0]

export const getLastDayOfMonth = (date: Date) =>
  new Date(Date.UTC(date.getFullYear(), date.getMonth() + 1, 0))
    .toISOString()
    .split('T')[0]

// Get next day in UTC
export const getNextDay = (date: Date) =>
  new Date(date.setUTCDate(date.getUTCDate() + 1)).toISOString().split('T')[0]

// Increment counter up to seats or max 25
export const loadingSeatsCounter = (
  seats: number,
  onUpdate: (count: number) => void,
) => {
  let count = 0
  const interval = setInterval(() => {
    count++
    onUpdate(count)
    if (count >= Math.min(seats, 25)) clearInterval(interval)
  }, 200)
  return Math.min(seats, 25)
}

// Format seat names by row
export const seatNamesFormatted = (tickets: Ticket[]) => {
  const seatsByPlan = new Map<string, Record<string, number[]>>()

  tickets.forEach(({ plan_name, seat_name }) => {
    const row = seat_name.replace(/\d/g, '') // Extract row (letters only)
    const number = parseInt(seat_name.replace(/\D/g, '')) // Extract seat number

    if (!seatsByPlan.has(plan_name)) seatsByPlan.set(plan_name, {})
    if (!seatsByPlan.get(plan_name)![row]) seatsByPlan.get(plan_name)![row] = []
    seatsByPlan.get(plan_name)![row].push(number)
  })

  return Array.from(
    seatsByPlan,
    ([plan, rows]) =>
      `${plan} ${Object.entries(rows)
        .map(
          ([row, numbers]) =>
            `${row}${Math.min(...numbers)}${numbers.length > 1 ? `-${Math.max(...numbers)}` : ''}`,
        )
        .join(', ')}`,
  ).join(', ')
}

// Get currency symbol
export const getCurrencySymbol = (currency: string) =>
  ({ GBP: '£', USD: '$', EUR: '€' })[currency] || '£'

// Remove tokens from localStorage
export const cleanTokenFromLocalStorage = () => {
  localStorage.removeItem('access_token')
  localStorage.removeItem('refresh_token')
}

// Extract only numbers from a string
export function filterNumbers(str: string) {
  return str.replace(/\D/g, '')
}

// Generate a unique ID
export const getUniqueId = uuidv4

// Check if a date is past today
export const pastToday = (date: Date) => date < new Date()

// Start an interval
export const startInterval = (
  ref: React.MutableRefObject<number | null>,
  callback: TimerHandler,
  delay?: number,
) => {
  if (ref.current) clearInterval(ref.current)
  ref.current = setInterval(callback, delay)
}

// Stop an interval
export const stopInterval = (ref: React.MutableRefObject<number | null>) => {
  if (ref.current !== null) {
    clearInterval(ref.current)
    ref.current = null
  }
}

// Check if a URL is a video
export const isVideo = (url: string) =>
  /\.(mov|mp4|avi|webm)$/.test(url.split('?')[0])

// Copy text to clipboard with fallback
export const copyToClipboard = async (text: string) => {
  try {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(text)
    } else {
      const textArea = document.createElement('textarea')
      textArea.value = text
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy')
      document.body.removeChild(textArea)
    }
    toast({ title: 'Copied to clipboard!' })
  } catch (err) {
    console.error('Failed to copy text: ', err)
    toast({ title: 'Failed to copy text!', error: true })
  }
}
