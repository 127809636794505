import {
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerDescription,
  DrawerFooter,
  Drawer,
  DrawerClose,
} from '@/components/ui/drawer'
import { transition } from '@/libs/utils'
import { motion } from 'framer-motion'
import { Plus } from 'lucide-react'
import LoadingIndicator from '../seatMap/LoadingIndicator'
import { Button } from '@/components/ui/button'
import { useSwapStore } from '@/context/useSwapStore'
import { useBasketStore } from '@/context/useBasketStore'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import { InfoIcon } from '@/assets/infoIcon'
import { useState } from 'react'
import { Modal } from '../modals'

interface SelectedSeatsProps {
  seat_name: string
  plan_name: string
}

interface SwapDrawerProps {
  selectedDate?: Date
  setSelectedDate: (date?: Date) => void
  availableDates: Date[]
  cartLoading: boolean
  error: string | undefined
  setError: (error: string | undefined) => void
}

export const SwapDrawer = ({
  selectedDate,
  setSelectedDate,
  availableDates,
  cartLoading,
  error,
  setError,
}: SwapDrawerProps) => {
  const callRouteWithDirection = useCallRouteWithDirection()
  const { basket } = useBasketStore()
  const { swapUid, newSeats, newEventDate, newEventTime } = useSwapStore()
  const [infoModal, setInfoModal] = useState(false)

  return (
    <Drawer
      open={!!selectedDate}
      onClose={() => {
        setInfoModal(false)
        setError(undefined)
        setSelectedDate(undefined)
      }}
    >
      <DrawerContent
        className="bg-purple pb-4 z-[500]"
        data-testid="swap-drawer"
      >
        {error && (
          <motion.div
            transition={transition}
            initial={{ opacity: 0 }}
            animate={{ opacity: error ? 1 : 0 }}
            exit={{ opacity: 0 }}
            className="flex justify-center items-center h-[30vh]"
          >
            <p className="text-white">{error}</p>
          </motion.div>
        )}

        {!error &&
          (!cartLoading ? (
            <motion.div
              transition={transition}
              initial={{ opacity: 0 }}
              animate={{ opacity: !cartLoading && !error ? 1 : 0 }}
              exit={{ opacity: 0 }}
              className="mx-auto w-full max-w-sm"
            >
              {availableDates.find((availableDate) => {
                return (
                  selectedDate &&
                  availableDate.toISOString().split('T')[0] ===
                    `${selectedDate.getFullYear()}-${String(selectedDate.getMonth() + 1).padStart(2, '0')}-${String(selectedDate.getDate()).padStart(2, '0')}`
                )
              }) ? (
                <>
                  <DrawerHeader className="relative flex justify-between items-start p-4 pt-8">
                    <div className="w-full flex flex-col gap-2">
                      <div className="w-full flex justify-between items-center">
                        <DrawerTitle className="text-sm flex gap-2">
                          {`Available Seats`}
                          {!infoModal && (
                            <p
                              data-testid="info-boxIcon"
                              className="relative text-sm flex gap-1 justify-end"
                              onClick={() => setInfoModal(true)}
                            >
                              <InfoIcon />
                            </p>
                          )}
                        </DrawerTitle>
                        <DrawerClose asChild>
                          <Button
                            variant="primaryDark"
                            data-testid="close-drawer"
                            className="p-0"
                          >
                            <Plus
                              className="z-10 rotate-45 font-light"
                              size={24}
                            />
                          </Button>
                        </DrawerClose>
                      </div>
                      {infoModal && (
                        <Modal
                          modalOpen={infoModal}
                          content={
                            <div className="relative flex flex-col gap-3 bg-purple2 rounded-[20px] p-4 mt-4 mb-2">
                              <div className="flex gap-2">
                                <span className="pt-[2px]">
                                  <InfoIcon />
                                </span>
                                <p className="font-bold">{`New Seats`}</p>
                              </div>
                              <p className="w-fit text-white text-sm leading-snug text-pretty">
                                {`If your original seats aren’t available, we select the next best ones for you.`}
                              </p>
                              <p className="w-fit text-white text-sm leading-snug text-pretty">
                                {`Prices can change, so if your original seats are more expensive now, the difference will be added at checkout.`}
                              </p>
                              <Plus
                                color="white"
                                data-testid="info-box-closeIcon"
                                onClick={() => setInfoModal(false)}
                                style={{
                                  rotate: '45deg',
                                  position: 'absolute',
                                  right: '12px',
                                  top: '7px',
                                }}
                                width={20}
                                height={20}
                              />
                            </div>
                          }
                        />
                      )}
                      <div className="flex flex-col gap-2 my-2">
                        <div className="w-full flex justify-between">
                          <DrawerDescription>{newEventDate}</DrawerDescription>
                          <DrawerDescription>{newEventTime}</DrawerDescription>
                        </div>
                        {Array.from(
                          new Map(
                            newSeats?.map((seat) => [seat.plan_name, []]),
                          ).keys(),
                        ).map((plan_name) => {
                          const seatsInPlan = newSeats?.filter(
                            (seat) => seat.plan_name === plan_name,
                          ) as SelectedSeatsProps[]

                          return (
                            <div
                              key={plan_name}
                              className="w-full flex justify-between"
                            >
                              <DrawerDescription>{plan_name}</DrawerDescription>
                              <DrawerDescription>
                                {`Row ` +
                                  seatsInPlan[0].seat_name.replace(
                                    /[^a-zA-Z]/g,
                                    '',
                                  ) +
                                  `, `}

                                {seatsInPlan.length > 1
                                  ? `${seatsInPlan[0].seat_name.replace(/\D/g, '')}-${seatsInPlan.at(-1)?.seat_name.replace(/\D/g, '') ?? ''}`
                                  : seatsInPlan[0].seat_name.replace(/\D/g, '')}
                              </DrawerDescription>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </DrawerHeader>
                  <DrawerFooter className="py-0">
                    {swapUid && (
                      <Button
                        variant="primaryYellow"
                        data-testid="select-button"
                        onClick={() => {
                          if (basket) {
                            callRouteWithDirection('/booking-summary', false, 1)
                          }
                        }}
                      >
                        {`Select`}
                      </Button>
                    )}
                  </DrawerFooter>
                </>
              ) : (
                <motion.div
                  transition={transition}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="flex justify-center items-center h-[30vh]"
                >
                  <p className="text-white">{`No seats available for this date.`}</p>
                </motion.div>
              )}
            </motion.div>
          ) : (
            <motion.div
              transition={transition}
              initial={{ opacity: 0 }}
              animate={{ opacity: cartLoading ? 1 : 0 }}
              exit={{ opacity: 0 }}
              data-testid="loading"
              className="flex justify-center items-center h-[25vh]"
            >
              <LoadingIndicator />
            </motion.div>
          ))}
      </DrawerContent>
    </Drawer>
  )
}
