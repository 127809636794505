import { requestWithRetry } from '../axios'

export const postSwapSeatSelected = async (
  order_uid: string,
  instance_uid: string,
) => {
  try {
    return await requestWithRetry(`swap/select`, 'post', {
      order_uid,
      instance_uid,
    })
  } catch (err) {
    throw err
  }
}
