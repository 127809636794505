export const VipDrinksIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
    >
      <path
        d="M7.5 9.5H25.5M7.5 23.5H25.5M8.5 13.5L10.5 19.5H11.5L13.5 13.5M16.5 13.5V19.5M20.5 19.5V13.5H22.5C23.0304 13.5 23.5391 13.7107 23.9142 14.0858C24.2893 14.4609 24.5 14.9696 24.5 15.5C24.5 16.0304 24.2893 16.5391 23.9142 16.9142C23.5391 17.2893 23.0304 17.5 22.5 17.5H20.5"
        stroke="#19162C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
