export const OrdersIconDark = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M9 1H3C2.60218 1 2.22065 1.15804 1.93934 1.43934C1.65804 1.72064 1.5 2.10218 1.5 2.5V9.9C1.49964 10.069 1.54211 10.2354 1.62347 10.3835C1.70483 10.5317 1.82241 10.6568 1.96523 10.7472C2.10805 10.8376 2.27145 10.8903 2.44017 10.9004C2.60888 10.9105 2.77741 10.8777 2.93 10.805C3.05 10.745 3.155 10.68 3.265 10.615C3.47656 10.4562 3.73553 10.3734 4 10.38C4.26601 10.3734 4.52655 10.4561 4.74 10.615C5.10921 10.8723 5.55001 11.007 6 11C6.45004 11.0074 6.89094 10.8726 7.26 10.615C7.47328 10.4558 7.73395 10.373 8 10.38C8.26459 10.3726 8.52381 10.4554 8.735 10.615C8.84331 10.6826 8.95513 10.7443 9.07 10.8C9.22222 10.8725 9.39029 10.9053 9.55859 10.8955C9.7269 10.8856 9.88998 10.8333 10.0327 10.7435C10.1754 10.6537 10.293 10.5293 10.3747 10.3818C10.4564 10.2343 10.4995 10.0686 10.5 9.9V2.5C10.5 2.10218 10.342 1.72064 10.0607 1.43934C9.77936 1.15804 9.39783 1 9 1ZM6 8H4C3.86739 8 3.74022 7.94732 3.64645 7.85355C3.55268 7.75979 3.5 7.63261 3.5 7.5C3.5 7.36739 3.55268 7.24021 3.64645 7.14645C3.74022 7.05268 3.86739 7 4 7H6C6.13261 7 6.25979 7.05268 6.35356 7.14645C6.44732 7.24021 6.5 7.36739 6.5 7.5C6.5 7.63261 6.44732 7.75979 6.35356 7.85355C6.25979 7.94732 6.13261 8 6 8ZM8 6H4C3.86739 6 3.74022 5.94732 3.64645 5.85355C3.55268 5.75979 3.5 5.63261 3.5 5.5C3.5 5.36739 3.55268 5.24021 3.64645 5.14645C3.74022 5.05268 3.86739 5 4 5H8C8.13261 5 8.25979 5.05268 8.35356 5.14645C8.44733 5.24021 8.5 5.36739 8.5 5.5C8.5 5.63261 8.44733 5.75979 8.35356 5.85355C8.25979 5.94732 8.13261 6 8 6ZM8 4H4C3.86739 4 3.74022 3.94732 3.64645 3.85355C3.55268 3.75979 3.5 3.63261 3.5 3.5C3.5 3.36739 3.55268 3.24021 3.64645 3.14645C3.74022 3.05268 3.86739 3 4 3H8C8.13261 3 8.25979 3.05268 8.35356 3.14645C8.44733 3.24021 8.5 3.36739 8.5 3.5C8.5 3.63261 8.44733 3.75979 8.35356 3.85355C8.25979 3.94732 8.13261 4 8 4Z"
      fill="#19162C"
    />
  </svg>
)
