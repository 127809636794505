import { AnimatePresence, motion, usePresence } from 'framer-motion'
import React, { useEffect, useState } from 'react'

import { transitionEasing } from '@/libs/global-vars'
import { useLocation } from 'react-router-dom'
import useNavigationStore from '@/context/useNavigationStore'

type RouteTransitionProps = {
  children: React.ReactNode
}

const RouteTransition = ({ children }: RouteTransitionProps) => {
  const location = useLocation()

  const transitionDuration = 0.25

  const [isPresent, safeToRemove] = usePresence()
  const { backNav } = useNavigationStore()

  useEffect(() => {
    !isPresent && setTimeout(safeToRemove, transitionDuration * 1000)
  }, [isPresent, safeToRemove])

  const transition = {
    ease: transitionEasing,
    duration: transitionDuration,
  }

  const [animation, setAnimation] = useState({
    initial: { x: backNav ? '-100%' : '100%', opacity: 0 },
    animate: { x: 0, opacity: 1 },
    exit: { x: backNav ? '100%' : '-100%', opacity: 0 },
  })

  useEffect(() => {
    setAnimation({
      initial: { x: backNav ? '-100%' : '100%', opacity: 0 },
      animate: { x: 0, opacity: 1 },
      exit: { x: backNav ? '100%' : '-100%', opacity: 0 },
    })
  }, [backNav])

  return (
    <AnimatePresence>
      <motion.div
        className="w-full h-full"
        variants={animation}
        key={location.key}
        transition={transition}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        {children}
      </motion.div>
    </AnimatePresence>
  )
}

export default RouteTransition
