import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import { useEffect, useState } from 'react'

import { Button } from '@/components/ui/button'
import DefaultLayout from '@/layouts/DefaultLayout'
import HeadlineBar from '@/components/upseat-ui/general/HeadlineBar'
import RouteTransition from '@/components/routeTransition/RouteTransition'
import { SearchInput } from '@/components/upseat-ui/general/SearchInput'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import { useUserStore } from '@/context/useUserStore'

export const HelpCentre = () => {
  const callRouteWithDirection = useCallRouteWithDirection()
  const [search, setSearch] = useState<string>('')
  const { user } = useUserStore()
  const [searchResults, setSearchResults] = useState<number>(0)
  const content = [
    {
      question: 'Can I upgrade?',
      answer: (
        <div className="flex flex-col gap-2">
          <p>
            {`Upgrades are limited, your ticket will show if they’re available.`}
          </p>
          <p>{`Remember, your old tickets are no longer valid, but keep hold of them in case the venue requests to see them. Upgrades are non-refundable.`}</p>
        </div>
      ),
    },
    {
      question: 'How do I access my tickets?',
      answer: `Barcodes will appear in your Up Wallet whenever you book tickets directly from our partner theatres.`,
    },
    {
      question: 'What if I can’t make the show?',
      answer: `Click ‘Manage’ on your ticket where you’ll be able to swap dates or sell a ticket back to the theatre. Subject to availability and T&Cs.`,
    },
    {
      question: 'What are the terms of use?',
      answer: (
        <p>
          {`Find our Terms of Use, Privacy Policy and Cookie Policy `}
          <a
            className="underline"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.notion.so/upseat/Our-legals-273016cae1d24dd4802f65e788c47631"
          >{`here`}</a>
          {`.`}
        </p>
      ),
    },
  ]

  useEffect(() => {
    setSearchResults(
      content.filter(
        (item) =>
          item.question.toLowerCase().includes(search.toLowerCase()) ||
          (typeof item.answer === 'string'
            ? item.answer.toLowerCase().includes(search.toLowerCase())
            : false),
      ).length,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  return (
    <DefaultLayout>
      <RouteTransition>
        <div className="flex flex-col justify-start gap-4">
          <HeadlineBar
            title={`help centre`}
            goBack={
              user
                ? () => callRouteWithDirection('/tickets', true, 3)
                : undefined
            }
          />
        </div>

        <main
          className="bg-white h-[80svh] flex flex-col gap-2 text-purple px-4 pt-6"
          data-testid="help-centre"
        >
          <SearchInput
            search={search}
            setSearch={setSearch}
            data-testid={`search-input`}
          />
          <p className="uppercase text-[12px] text-purple2 pl-2 my-2">
            {search ? `search results` : `frequently asked questions`}
          </p>
          {search && searchResults > 0 && (
            <p className="font-semibold text-purple2 pl-2">{`${searchResults} results for '${search}'`}</p>
          )}
          {search && !searchResults && (
            <div className="flex flex-col gap-1">
              <h4 className="text-h4 text-purple">{'sorry!'}</h4>
              <p className="text-sm font-semibold">{`No results for '${search}'`}</p>
            </div>
          )}
          <div className="relative top-0 h-auto pb-12 overflow-scroll">
            {content
              .filter(
                (item) =>
                  item.question.toLowerCase().includes(search.toLowerCase()) ||
                  (typeof item.answer === 'string'
                    ? item.answer.toLowerCase().includes(search.toLowerCase())
                    : false),
              )
              .map((item, index) => {
                return (
                  <Accordion
                    key={`item-` + index}
                    type="single"
                    collapsible
                    className={`w-full ${index > 0 && 'mt-2'} flex flex-col gap-2`}
                  >
                    <AccordionItem
                      value={`item-${index}`}
                      className="relative border-none bg-grey4 text-purple1 rounded-[20px] p-4 py-2"
                    >
                      <AccordionTrigger className="font-body font-normal">
                        {item.question}
                      </AccordionTrigger>
                      <AccordionContent>
                        <div className="w-full h-full leading-[1.75] py-2">
                          {item.answer}
                        </div>
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                )
              })}
          </div>
          <footer className="bg-purple absolute flex items-center justify-center left-0 bottom-0 text-white w-full h-[10svh] py-4">
            <div className="flex items-center justify-center gap-2">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://54dleo9n841.typeform.com/to/zfteuTMG"
              >
                <Button
                  variant="secondaryDark"
                  className="py-2 px-4 text-sm font-normal text-[yellow] border-[yellow]"
                >
                  {`Report a bug`}
                </Button>
              </a>
              <p>{`or`}</p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://54dleo9n841.typeform.com/to/zfteuTMG"
              >
                <Button
                  variant="secondaryDark"
                  className="py-2 px-4 text-sm font-normal text-[yellow] border-[yellow]"
                >
                  {`Get in touch`}
                </Button>
              </a>
            </div>
          </footer>
        </main>
      </RouteTransition>
    </DefaultLayout>
  )
}
