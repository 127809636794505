import { useEffect } from 'react'

const useViewportScroll = (pageRef: React.RefObject<HTMLDivElement>) => {
  useEffect(() => {
    const visualViewport = window.visualViewport
    if (!visualViewport) return

    const pageElement = pageRef.current // Store current ref value

    // Store original body styles to restore later
    const originalStyles = {
      position: document.body.style.position,
      top: document.body.style.top,
      width: document.body.style.width,
      height: document.body.style.height,
      overflow: document.body.style.overflow,
      touchAction: document.body.style.touchAction,
      transition: document.body.style.transition,
    }

    let isKeyboardOpen = false
    let viewportHeight = window.innerHeight
    let scrollPosition = 0

    if (pageElement) {
      pageElement.style.transition = 'transform 0.3s ease-out'
    }

    const handleResize = () => {
      const heightDifference = viewportHeight - visualViewport.height
      const keyboardIsLikelyOpen = heightDifference > 150

      if (keyboardIsLikelyOpen !== isKeyboardOpen) {
        isKeyboardOpen = keyboardIsLikelyOpen

        if (isKeyboardOpen) {
          scrollPosition = window.scrollY

          document.body.style.position = 'fixed'
          document.body.style.top = `-${scrollPosition}px`
          document.body.style.width = '100%'
          document.body.style.height = '100%'
          document.body.style.overflow = 'hidden'
          document.body.style.touchAction = 'none'

          if (pageElement) {
            const activeElement = document.activeElement as HTMLElement
            let adjustedOffset = heightDifference

            if (
              activeElement &&
              (activeElement.tagName === 'INPUT' ||
                activeElement.tagName === 'TEXTAREA')
            ) {
              const inputRect = activeElement.getBoundingClientRect()
              const inputBottom = inputRect.bottom

              if (inputBottom > visualViewport.height * 0.5) {
                const additionalOffset = Math.min(inputRect.top - 100, 0)
                adjustedOffset = heightDifference - additionalOffset
              }
            }

            pageElement.style.transform = `translateY(-${adjustedOffset}px)`
          }
        } else {
          if (pageElement) {
            pageElement.style.transform = ''
          }

          setTimeout(() => {
            document.body.style.position = originalStyles.position
            document.body.style.top = originalStyles.top
            document.body.style.width = originalStyles.width
            document.body.style.height = originalStyles.height
            document.body.style.overflow = originalStyles.overflow
            document.body.style.touchAction = originalStyles.touchAction

            window.scrollTo(0, scrollPosition)
          }, 300)
        }
      }
    }

    const preventScroll = (e: TouchEvent) => {
      if (isKeyboardOpen) {
        const target = e.target as Element
        const isInput =
          target.tagName === 'INPUT' ||
          target.tagName === 'TEXTAREA' ||
          (target as HTMLElement).isContentEditable

        if (!isInput || document.activeElement !== target) {
          e.preventDefault()
        }
      }
    }

    const handleTouchEnd = (e: TouchEvent) => {
      if (isKeyboardOpen) {
        const target = e.target as Element
        const isInput =
          target.tagName === 'INPUT' ||
          target.tagName === 'TEXTAREA' ||
          (target as HTMLElement).isContentEditable

        if (!isInput) {
          if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur()
          }
        }
      }
    }

    visualViewport.addEventListener('resize', handleResize)
    document.addEventListener('touchmove', preventScroll, { passive: false })
    document.addEventListener('touchend', handleTouchEnd)

    return () => {
      visualViewport.removeEventListener('resize', handleResize)
      document.removeEventListener('touchmove', preventScroll)
      document.removeEventListener('touchend', handleTouchEnd)

      document.body.style.position = originalStyles.position
      document.body.style.top = originalStyles.top
      document.body.style.width = originalStyles.width
      document.body.style.height = originalStyles.height
      document.body.style.overflow = originalStyles.overflow
      document.body.style.touchAction = originalStyles.touchAction

      if (pageElement) {
        pageElement.style.transition = ''
        pageElement.style.transform = ''
      }
    }
  }, [pageRef]) // Depend on `pageRef.current` instead of `pageRef`
}

export default useViewportScroll
