import { requestWithRetry } from '../axios'

export const postTickets = async (
  order_uid: string,
  seat_ids: string[],
  band_uid: string,
) => {
  const response = await requestWithRetry(`upgrade/tickets`, 'post', {
    order_uid,
    seat_ids,
    band_uid,
  })
  return response
}
