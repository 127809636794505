export const FooterLinks = () => (
  <div className="pb-2">
    <p className="text-white text-center text-[12px] leading-normal text-balance">
      {`By signing up you agree to Up's`}
      <a
        className="text-purple5"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.notion.so/upseat/Our-legals-273016cae1d24dd4802f65e788c47631"
      >
        {` Terms & Conditions`}
      </a>
    </p>
  </div>
)
