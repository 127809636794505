import {
  Carousel,
  CarouselContent,
  CarouselDots,
  CarouselItem,
  CarouselNext,
} from '@/components/upseat-ui/tickets/OnboardingTicketCarousel'
import { useRef, useState } from 'react'
import Lottie, { LottieRefCurrentProps } from 'lottie-react'
import animationStep1 from '@/assets/animations/onboarding_step_1.json'
import animationStep2 from '@/assets/animations/cookie_animation.json'

import useScreenSize from '@/hooks/useScreenSize'

export function OnboardingTicket() {
  const screenSize = useScreenSize()
  const [currentIndex, setCurrentIndex] = useState(0)
  const lottieRef = useRef<LottieRefCurrentProps | null>(null)

  const renderCookieAnimation = () => {
    lottieRef.current?.pause()

    setTimeout(() => {
      lottieRef.current?.play()
    }, 1000)

    return (
      <Lottie
        animationData={animationStep2}
        loop={false}
        className="h-[22vh]"
        lottieRef={lottieRef}
      />
    )
  }

  return (
    <div
      id="onboarding-ticket-card"
      className={`rounded-[40px] bg-purple8 flex flex-col items-center justify-between ${
        screenSize.width > 1024 ? 'w-[25vw]' : 'w-[80vw]'
      } h-[80svh] px-4 py-6 mt-16`}
    >
      {animationStep1 && animationStep2 && (
        <Carousel
          className="w-full h-full flex flex-col items-center justify-between gap-4"
          onIndexChange={(index) => setCurrentIndex(index)}
        >
          <CarouselContent className="relative h-auto gap-4">
            <CarouselItem
              id={'step1'}
              className="flex flex-col items-center basis-full pt-12"
            >
              {currentIndex === 0 && (
                <>
                  <Lottie animationData={animationStep1} loop={false} />
                  <p
                    id="onboarding-ticket-card-text-1"
                    className="text-white text-balance text-center h-auto flex items-start justify-center pt-4"
                  >
                    {
                      'Whenever you book tickets directly from the theatre, they’ll appear right here.'
                    }
                  </p>
                </>
              )}
            </CarouselItem>

            <CarouselItem
              id={'step2'}
              className="flex flex-col items-center basis-full py-4"
            >
              {currentIndex === 1 && (
                <div className="flex flex-col gap-4 pt-12 items-center justify-center">
                  {renderCookieAnimation()}
                  <p className="font-bold">{`Cookies you’ll actually want`}</p>
                  <p
                    id="onboarding-ticket-card-text-2"
                    className="text-white text-balance text-center h-auto px-2 pt-4"
                  >
                    Experiences are better when they’re made just for you. By
                    clicking Accept, you consent to our{' '}
                    <span className="text-purple4">{`cookie policy`}</span>.
                  </p>
                </div>
              )}
            </CarouselItem>
          </CarouselContent>

          <CarouselDots />
          <CarouselNext
            data-testid="onboarding-carousel-next"
            variant={'primaryYellow'}
          />
        </Carousel>
      )}
    </div>
  )
}
