import { AnimatePresence, motion } from 'framer-motion'
import { Eye, EyeOff } from 'lucide-react'
import { transition } from '@/libs/utils'
import { useEffect, useState } from 'react'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import LoadingIndicator from '@/components/upseat-ui/seatMap/LoadingIndicator'
import PublicLayout from '@/layouts/PublicLayout'
import RouteTransition from '@/components/routeTransition/RouteTransition'
import { getUser } from '@/services/User/getUser'
import { postLogin } from '@/services/Login/postLogin'
import { useAppStore } from '@/context/useAppStore'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import { useNavigate } from 'react-router-dom'
import { useUserStore } from '@/context/useUserStore'

export const LoginPage = () => {
  const navigate = useNavigate()
  const { user, setUser } = useUserStore()
  const { errors, setAppStore } = useAppStore()
  const callRouteWithDirection = useCallRouteWithDirection()
  const [loadingLogin, setLoadingLogin] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [error, setError] = useState<React.ReactNode | null>()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  useEffect(() => {
    setError(null)
  }, [email, password])

  useEffect(() => {
    if (user) {
      callRouteWithDirection('/tickets', false, 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const LoginSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setError(null)
    setLoadingLogin(true)

    try {
      const loginResponse = await postLogin(email, password)

      await localStorage.setItem('access_token', loginResponse.access_token)
      await localStorage.setItem('refresh_token', loginResponse.refresh_token)

      const userResponse = await getUser()
      setUser({
        uid: userResponse.uid,
        username: userResponse.email.split('@')[0],
        firstname: userResponse.first_name,
        lastname: userResponse.last_name,
        email: userResponse.email,
        phoneNumber: userResponse.phone_number,
        cookies: userResponse.cookies,
        marketing: userResponse.marketing,
      })

      callRouteWithDirection('/tickets', true, 1)
    } catch (error: any) {
      console.log('postLogin Error:', error)

      if (error.response) {
        switch (error.response.data.error) {
          case 'Account locked - Contact Administrator':
            setError(
              <p className="mb-4 text-pretty">
                {`Too many unsuccessful attempts. Please contact us through this `}
                <a
                  target="_blank"
                  className="underline font-bold text-purple4"
                  rel="noopener noreferrer"
                  href="https://54dleo9n841.typeform.com/to/zfteuTMG"
                >
                  link
                </a>
              </p>,
            )
            break
          case 'Beta access only for staff members':
            setError(error.response.data.error)
            break
          case 'invalid_grant':
            setError('Wrong email or password. Please try again.')
            break
          case 'invalid_client':
            setError('Error on the request. Please try again.')
            break
          default:
            setError(
              <p>
                {error.response.data.error_description ||
                  'An error occurred. Please try again.'}
              </p>,
            )
        }
      }

      if (error.message === 'Request timed out') {
        setAppStore({
          errors: {
            ...errors,
            server: true,
          },
        })
      }
    } finally {
      setLoadingLogin(false)
    }
  }

  return (
    <PublicLayout>
      <RouteTransition>
        <main
          data-testid="login"
          className="h-full flex flex-col justify-end pb-[15vh]"
        >
          <form
            id="log-in-form"
            className="overflow-scroll flex flex-col gap-4 text-purple px-4"
            onSubmit={(e) => LoginSubmit(e)}
          >
            <h3 className="text-h3 text-white">{`login`}</h3>
            <div className="flex gap-2">
              <Input
                id="login-form-email"
                variant="dark"
                className={`py-4 ${error && !email.length ? 'border-red placeholder:text-red' : ''}`}
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
              />
            </div>
            <div className="relative flex-col items-center justify-center">
              <Input
                id="login-form-password"
                variant="dark"
                className={`py-4 ${error && !password.length ? 'border-red placeholder:text-red' : ''}`}
                placeholder="Password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <label
                onClick={() => setShowPassword(!showPassword)}
                className="absolute top-5 right-4 bg-gray-300 hover:bg-gray-400 rounded text-sm text-gray-600 font-mono cursor-pointer"
              >
                {showPassword ? (
                  <Eye
                    data-testid="toggle-password-visibility"
                    color={`${error && !password.length ? '#ef6363' : '#CDC3FB'}`}
                    size={16}
                  />
                ) : (
                  <EyeOff
                    data-testid="toggle-password-visibility"
                    color={`${error && !password.length ? '#ef6363' : '#CDC3FB'}`}
                    size={16}
                  />
                )}
              </label>
              {error && (
                <div
                  data-cy="login-error"
                  className="text-red text-[12px] pl-2 pt-1 text-balance relative"
                >
                  {!email.length || !password.length
                    ? `Field${!email.length && !password.length ? 's ' : ''} cannot be empty.`
                    : error}
                </div>
              )}
            </div>
            <div className="flex flex-col gap-2 mb-2">
              <div className="w-full flex justify-end">
                <p
                  onClick={() =>
                    callRouteWithDirection('/forgot-password', false, 1)
                  }
                  className="relative bottom-1 mb-3 pr-2 text-sm text-right text-purple5 w-fit"
                >{`Forgot your password?`}</p>
              </div>
              {!loadingLogin ? (
                <Button
                  id="login-form-button"
                  variant="primaryYellow"
                  className="w-full"
                  type="submit"
                >{`Log in`}</Button>
              ) : (
                <AnimatePresence>
                  <motion.div
                    transition={transition}
                    initial={{
                      opacity: 0,
                    }}
                    animate={{ opacity: 1 }}
                    className="py-6"
                  >
                    <LoadingIndicator />
                  </motion.div>
                </AnimatePresence>
              )}
            </div>
          </form>
          <div className="flex text-center text-white items-center justify-center gap-2 py-6">
            <p>{`Don’t have an account?`}</p>
            <Button
              variant="textOnly"
              className="py-2 px-4 text-white text-sm border-white border-[1.5px]"
              onClick={() => navigate('/sign-up')}
            >
              {`Get started here`}
            </Button>
          </div>
        </main>
      </RouteTransition>
    </PublicLayout>
  )
}
