import { Booking, Order } from '@/types/booking'

import PriceSlider from '@/components/upseat-ui/seatMap/PriceSlider'
import { Seat } from '@/types'
import SeatMapRouter from './SeatMapRouter'
import useEventStore from '@/context/useEventStore'
import { Skeleton } from '@/components/ui/skeleton'

export const isCurrentSeat = (
  order: Order | undefined,
  seatLocation: string,
  seatPlan: string,
) => {
  let currentSeat = false
  order?.tickets?.forEach((seat) => {
    if (seat.seat_name === seatLocation && seat.plan_name === seatPlan) {
      currentSeat = true
    }
  })

  return currentSeat
}

export const selectSeat = (
  seat: Seat,
  selectedSeats: Seat[],
  setSelectedSeats: (selectedSeats: Seat[]) => void,
  setSeatAsideWarning: (value: React.SetStateAction<boolean>) => void,
) => {
  // Check if the neighbor seat is in the same row and adjacent by seat.number + 1 or -1
  if (
    selectedSeats.includes(seat) ||
    !selectedSeats.length ||
    (selectedSeats.length > 0 &&
      selectedSeats.some(
        (selectedSeat: Seat) =>
          selectedSeat.row === seat.row &&
          selectedSeat.location === seat.location &&
          (parseInt(selectedSeat.number) === parseInt(seat.number) + 1 ||
            parseInt(selectedSeat.number) === parseInt(seat.number) - 1),
      ))
  ) {
    if (
      selectedSeats.some(
        (selectedSeat) => selectedSeat.seat_id === seat.seat_id,
      )
    ) {
      // Remove the seat if already selected
      setSelectedSeats(
        selectedSeats
          .filter((selectedSeat) => selectedSeat.seat_id !== seat.seat_id)
          .sort(
            (a, b) =>
              parseInt(a.location.slice(1)) - parseInt(b.location.slice(1)),
          ),
      )
    } else {
      // Add the seat to the selected seats
      setSelectedSeats([...selectedSeats, seat])
    }
  } else {
    // Show a warning if the seat is not adjacent
    setSeatAsideWarning(true)
  }
}

export const filterResults = (tier: number, discountRange: number[]) => {
  // check if the tier is within the discount range
  if (tier - 1 >= discountRange[0] && tier - 1 <= discountRange[1]) {
    return
  } else {
    return 'opacity-[.2] pointer-events-none'
  }
}

interface SeatMapProps {
  bookingData: Booking
}

export const SeatMap = ({ bookingData }: SeatMapProps) => {
  const { priceBands, mapSeats } = useEventStore()

  return (
    <div className="seat-map-container p-0">
      {priceBands.length && mapSeats?.length ? (
        <>
          <div
            className={`select-price-range w-full rounded-containers px-4 ${priceBands.length > 1 && 'pt-6 pb-2'}`}
          >
            <PriceSlider pricePoints={priceBands} />
          </div>
          <SeatMapRouter seatMapID={bookingData.config.uid} />
        </>
      ) : (
        <div className="flex flex-col gap-6 p-6 h-[60vh]">
          <Skeleton className="bg-purple3/30 w-full h-16" />
          <Skeleton className="bg-purple3/30 w-full h-80" />
        </div>
      )}
    </div>
  )
}
