export const CastIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M8.86159 19.45C7.756 19.0426 6.77214 18.3608 6.00246 17.4687C5.23278 16.5765 4.70255 15.5034 4.46159 14.35L2.80159 6.54001C2.56159 5.46001 3.25159 4.40001 4.33159 4.17001L14.1016 2.10001L14.1316 2.09001C15.2016 1.88001 16.2516 2.57001 16.4716 3.63001L16.8216 5.30001L21.1716 6.23001H21.2016C22.2516 6.47001 22.9316 7.53001 22.7116 8.59001L21.0516 16.41C20.86 17.3083 20.4933 18.1601 19.9725 18.9166C19.4516 19.6732 18.7868 20.3198 18.0161 20.8194C17.2453 21.319 16.3837 21.6618 15.4804 21.8284C14.5771 21.9949 13.6498 21.9819 12.7516 21.79C11.2281 21.4711 9.85721 20.6464 8.86159 19.45ZM20.7516 8.18001L10.9816 6.10001L9.32159 13.92V13.95C8.75159 16.63 10.4816 19.27 13.1716 19.84C15.8616 20.41 18.5216 18.69 19.0916 16L20.7516 8.18001ZM16.7516 16.5C16.4359 17.035 15.9599 17.4572 15.3909 17.7067C14.822 17.9561 14.189 18.0203 13.5816 17.89C12.9758 17.761 12.4255 17.4458 12.0078 16.9886C11.59 16.5313 11.3256 15.9549 11.2516 15.34L16.7516 16.5ZM9.22159 5.17001L4.75159 6.13001L6.41159 13.94L6.42159 13.97C6.57159 14.68 6.87159 15.32 7.28159 15.87C7.18159 15.1 7.20159 14.3 7.37159 13.5L7.80159 11.5C7.35159 11.42 6.96159 11.17 6.75159 10.81C6.81159 10.2 7.31159 9.66001 8.00159 9.50001H8.25159L9.03159 5.69001C9.07159 5.50001 9.13159 5.33001 9.22159 5.17001ZM15.7816 12.23C16.1016 11.7 16.7816 11.42 17.4716 11.57C18.1616 11.71 18.6616 12.24 18.7516 12.86C18.4216 13.38 17.7516 13.66 17.0516 13.5C16.3616 13.37 15.8616 12.84 15.7816 12.23ZM10.9016 11.19C11.2216 10.66 11.8916 10.38 12.5816 10.53C13.2516 10.67 13.7816 11.21 13.8616 11.82C13.5316 12.34 12.8616 12.63 12.1716 12.5C11.4816 12.33 10.9816 11.8 10.9016 11.19ZM12.7216 4.43001L14.6816 4.85001L14.5216 4.05001L12.7216 4.43001Z"
        fill="white"
      />
    </svg>
  )
}

export const DrinkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M12.25 13C18.25 13 20.25 8.523 20.25 3H4.25C4.25 8.523 6.25 13 12.25 13ZM12.25 13V20M12.25 20L16.25 22H8.25L12.25 20ZM5.25 8H19.25"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const MerchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M8.75 12V8C8.75 6.93913 9.17143 5.92172 9.92157 5.17157C10.6717 4.42143 11.6891 4 12.75 4C13.8109 4 14.8283 4.42143 15.5784 5.17157C16.3286 5.92172 16.75 6.93913 16.75 8V12"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M4.44265 12.668C4.58765 10.927 4.66065 10.057 5.23465 9.528C5.80865 8.999 6.68265 9 8.42965 9H17.0687C18.8147 9 19.6887 9 20.2627 9.528C20.8367 10.056 20.9097 10.927 21.0547 12.668L21.5687 18.834C21.6527 19.847 21.6947 20.354 21.3987 20.677C21.1007 21 20.5927 21 19.5747 21H5.92265C4.90565 21 4.39665 21 4.09965 20.677C3.80265 20.354 3.84465 19.847 3.92965 18.834L4.44265 12.668Z"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  )
}

export const ShareIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M5.875 10.5947C5.77554 10.5947 5.68016 10.6342 5.60984 10.7045C5.53951 10.7748 5.5 10.8702 5.5 10.9697V20.7197C5.5 20.9267 5.668 21.0947 5.875 21.0947H18.625C18.7245 21.0947 18.8198 21.0552 18.8902 20.9848C18.9605 20.9145 19 20.8191 19 20.7197V10.9697C19 10.8702 18.9605 10.7748 18.8902 10.7045C18.8198 10.6342 18.7245 10.5947 18.625 10.5947H17.125C16.8266 10.5947 16.5405 10.4761 16.3295 10.2652C16.1185 10.0542 16 9.76804 16 9.46967C16 9.1713 16.1185 8.88515 16.3295 8.67417C16.5405 8.4632 16.8266 8.34467 17.125 8.34467H18.625C20.074 8.34467 21.25 9.52067 21.25 10.9697V20.7197C21.25 21.4159 20.9734 22.0835 20.4812 22.5758C19.9889 23.0681 19.3212 23.3447 18.625 23.3447H5.875C5.17881 23.3447 4.51113 23.0681 4.01884 22.5758C3.52656 22.0835 3.25 21.4159 3.25 20.7197V10.9697C3.25 9.52067 4.426 8.34467 5.875 8.34467H7.375C7.67337 8.34467 7.95952 8.4632 8.1705 8.67417C8.38147 8.88515 8.5 9.1713 8.5 9.46967C8.5 9.76804 8.38147 10.0542 8.1705 10.2652C7.95952 10.4761 7.67337 10.5947 7.375 10.5947H5.875ZM11.9845 1.11017C12.0193 1.07525 12.0607 1.04754 12.1063 1.02864C12.1518 1.00973 12.2007 1 12.25 1C12.2993 1 12.3482 1.00973 12.3937 1.02864C12.4393 1.04754 12.4807 1.07525 12.5155 1.11017L16.8595 5.45417C16.9121 5.50662 16.9479 5.5735 16.9624 5.64633C16.977 5.71916 16.9695 5.79466 16.9411 5.86327C16.9126 5.93187 16.8645 5.99049 16.8027 6.03169C16.7409 6.07288 16.6683 6.0948 16.594 6.09467H13.375V14.7197C13.375 15.018 13.2565 15.3042 13.0455 15.5152C12.8345 15.7261 12.5484 15.8447 12.25 15.8447C11.9516 15.8447 11.6655 15.7261 11.4545 15.5152C11.2435 15.3042 11.125 15.018 11.125 14.7197V6.09467H7.906C7.83173 6.0948 7.7591 6.07288 7.69731 6.03169C7.63552 5.99049 7.58735 5.93187 7.55891 5.86327C7.53047 5.79466 7.52305 5.71916 7.53757 5.64633C7.5521 5.5735 7.58792 5.50662 7.6405 5.45417L11.9845 1.11017Z"
        fill="white"
      />
    </svg>
  )
}
