import { apiClient } from '../axios'

export const registerUser = async (
  first_name: string,
  last_name: string,
  phone_number: string,
  email: string,
  password: string,
  marketing: boolean,
) => {
  const response = await apiClient.post('upauth/register', {
    first_name,
    last_name,
    phone_number,
    email,
    password,
    marketing,
  })
  return response?.data
}
