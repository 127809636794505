import { motion } from 'framer-motion'
import UpAppLogo from '@/assets/logos/upseat_logo.svg'

export const LoadingScreen = ({ screen }: { screen: { width: number } }) => (
  <div className="bg-purple gap-4 w-[100vw] overflow-hidden h-[100svh] flex flex-col justify-center items-center text-white">
    <motion.img
      src={UpAppLogo}
      alt="upSeat-logo"
      className={`${screen.width > 1024 ? 'w-[4vw]' : 'w-[20vw]'}`}
      initial={{ scale: 0.5 }}
      animate={{ rotate: 0, scale: 1 }}
      transition={{ type: 'spring', stiffness: 260, damping: 20 }}
    />
  </div>
)
