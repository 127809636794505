import { Order } from '@/types/booking'
import { create } from 'zustand'

// Define the store's state type
type SwapStoreState = {
  swapOn: boolean
  swapUid?: string
  newEventDate?: string
  newEventTime?: string
  newSeats?: {
    seat_name: string
    plan_name: string
  }[]
  setSwapUid: (swapUid: string) => void
  setSwapOn: (swapOn: boolean) => void
  setNewEventDate: (newEventDate: string) => void
  setNewEventTime: (newEventTime: string) => void
  setNewSeats: (newSeats: Order['tickets']) => void
}

// Create the store
export const useSwapStore = create<SwapStoreState>((set) => ({
  swapOn: false,
  swapUid: undefined,
  newEventDate: undefined,
  newEventTime: undefined,
  newSeats: undefined,
  setSwapOn: (swapOn: boolean) => set({ swapOn }),
  setSwapUid: (swapUid: string) => set({ swapUid }),
  setNewEventDate: (newEventDate: string) => set({ newEventDate }),
  setNewEventTime: (newEventTime: string) => set({ newEventTime }),
  setNewSeats: (newSeats: Order['tickets']) => set({ newSeats }),
}))
