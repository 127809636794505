import { PriceBand, Seat } from '@/types'

import { AnimatePresence } from 'framer-motion'
import { BookingDetails } from '@/components/upseat-ui/seatMap/BookingDetails/index'
import { CartSlide } from '@/components/upseat-ui/wallet/CartSlide'
import DefaultLayout from '@/layouts/DefaultLayout'
import HeadlineBar from '@/components/upseat-ui/general/HeadlineBar'
import RouteTransition from '@/components/routeTransition/RouteTransition'
import { SeatMap } from '@/components/upseat-ui/seatMap'
import { getPlan } from '@/services/Seats/getPlan'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import { useCallback, useEffect } from 'react'
import useEventStore from '@/context/useEventStore'
import useExistingBookingStore from '@/context/useExistingBookingStore'
import { useNavigate, useSearchParams } from 'react-router-dom'

// Function to get consecutive seat groups
export const getConsecutiveSeatGroups = (seats: string | any[]) => {
  const consecutiveGroups = []
  let currentGroup = [seats[0]]

  for (let i = 1; i < seats.length; i++) {
    if (seats[i].number - seats[i - 1].number === 1) {
      // Seats are consecutive, add to current group
      currentGroup.push(seats[i])
    } else {
      // Seats are not consecutive, start a new group
      consecutiveGroups.push([...currentGroup])
      currentGroup = [seats[i]]
    }
  }

  for (let i = 1; i < seats.length; i++) {
    if (seats[i].location - seats[i - 1].location <= 1) {
      // Seats are consecutive or neighbors, add to current group
      currentGroup.push(seats[i].location)
    } else {
      // Seats are not consecutive or neighbors, start a new group
      consecutiveGroups.push([...currentGroup.sort((a, b) => a - b)])
      currentGroup = [seats[i].location]
    }
  }

  // Add the last group
  consecutiveGroups.push([...currentGroup])

  return consecutiveGroups.filter((group) => group.length >= 2)
}

export const Upbooking = () => {
  const callRouteWithDirection = useCallRouteWithDirection()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const upgradingFromContent = searchParams.get('content')
  const { order, booking } = useExistingBookingStore()
  const {
    mapSeats,
    setMapSeats,
    setPriceBands,
    setSelectedSeats,
    setSearchingSeats,
    selectedSeats,
  } = useEventStore()

  const goBack = useCallback(async () => {
    setSelectedSeats([])
    if (upgradingFromContent) {
      callRouteWithDirection(
        `/channel/${booking?.channel.event_uid}?order=${order?.uid}`,
        true,
        1,
      )
    } else {
      setMapSeats([])
      callRouteWithDirection('/tickets', true, 1)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setMapSeats])

  useEffect(() => {
    setSearchingSeats(false)
    !booking && callRouteWithDirection('/tickets', true, 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateSeatsAvailability = async () => {
    const plan = await getPlan(order?.uid as string)

    if (!plan.bands) return

    const priceBands = plan.bands
      .sort(
        (a: PriceBand, b: PriceBand) =>
          (a.upgrade_values.discount_percentage ?? 0) -
          (b.upgrade_values.discount_percentage ?? 0),
      )
      .filter(
        (band: PriceBand) =>
          band.upgrade_values.discount_percentage &&
          band.upgrade_values.discount_percentage > 0,
      )

    setPriceBands(priceBands)

    const updatedSeats = plan.seats.map((seat: Seat) => {
      const priceBandIndex = priceBands.findIndex(
        (priceBand: PriceBand) => priceBand.uid === seat.band_uid,
      )
      return {
        ...seat,
        tier: priceBandIndex + 1,
        upgrade_price:
          priceBands[priceBandIndex]?.upgrade_values.unit_upgrade_price,
        original_price:
          priceBands[priceBandIndex]?.upgrade_values.unit_original_price,
        discount:
          priceBands[priceBandIndex]?.upgrade_values.discount_percentage,
        saved_price: priceBands[priceBandIndex]?.upgrade_values.saved_price,
        number_tickets:
          priceBands[priceBandIndex]?.upgrade_values.number_tickets,
      }
    })

    !updatedSeats.length && navigate('/tickets')
    setMapSeats(updatedSeats)
  }

  useEffect(() => {
    if (mapSeats.length && !selectedSeats.length) {
      const interval = setInterval(async () => {
        await updateSeatsAvailability()
        setSelectedSeats([])
        // timer to update seats availability every 7.5 seconds
      }, 7500)
      return () => clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSeats])

  return (
    <>
      <DefaultLayout>
        <RouteTransition>
          <div className="flex flex-col justify-start">
            <HeadlineBar
              title={
                <div className="flex items-center">{`choose your UpSeats`}</div>
              }
              goBack={goBack}
            />
            <div className="select-seats w-full">
              <div
                data-testid="upbooking"
                className={`bg-white text-purple h-full pb-[20vh] flex flex-col`}
              >
                {booking && (
                  <>
                    <BookingDetails bookingData={booking} />
                    <SeatMap bookingData={booking} />
                  </>
                )}
              </div>
            </div>
          </div>
        </RouteTransition>
      </DefaultLayout>

      {selectedSeats?.length && (
        <AnimatePresence>
          <CartSlide />
        </AnimatePresence>
      )}
    </>
  )
}
