import { MoveUp } from 'lucide-react'
import { Arrow } from '@/assets/Arrow'
import NavLayout from '@/layouts/NavLayout'
import { isVideo } from '@/libs/utils'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ChannelItem, TileItem, TileType } from '@/types/preshow'
import { ActionBar } from '@/components/upseat-ui/contentChannel/ActionBar'
import { ContentGrid } from '@/components/upseat-ui/contentChannel/ContentGrid'
import { MainVideo } from '@/components/upseat-ui/contentChannel/MainVideo'
import { getChannel } from '@/services/PreShowEngagement/getChannel'
import { AnimatePresence, motion } from 'framer-motion'
import {
  useContentChannelStore,
  VIEW_STATES,
} from '@/context/useContentChannelStore'
import useExistingBookingStore from '@/context/useExistingBookingStore'
import { useFullScreenContentStore } from '@/context/useFullScreenContentStore'
import { useMeetTheCastStore } from '@/context/useMeetTheCastStore'
import useScreenSize from '@/hooks/useScreenSize'
import { useUserStore } from '@/context/useUserStore'
import { UpSeatLogo } from '@/assets/logos/upseat_logo'
import { FullScreen } from './FullScreen'
import { MeetTheCast } from './MeetTheCast'
import { Carousel } from './Carousel'
import { LogoFlip } from '@/components/upseat-ui/contentChannel/logoFlipper'

export const ContentChannel = () => {
  const { id } = useParams()
  const sectionRef = useRef<HTMLElement>(null)
  const mainContentRef = useRef<HTMLElement>(null)
  const { user } = useUserStore()
  const { booking } = useExistingBookingStore()
  const navigate = useNavigate()
  const { setCasting, setBadgeUrl } = useMeetTheCastStore()
  const { setFullScreenChannel } = useFullScreenContentStore()

  const {
    channelContent,
    setChannelContent,
    currentView,
    scrollPosition,
    setScrollPosition,
  } = useContentChannelStore()

  const [mainContentLoaded, setMainContentLoaded] = useState(false)
  const [gridContent, setGridContent] = useState<ChannelItem[]>([])

  // Determine if main content view is active
  const isMainContentView = currentView === VIEW_STATES.MAIN_CONTENT

  // Scroll to saved position when view changes
  useEffect(() => {
    if (isMainContentView) {
      setTimeout(() => window.scrollTo(0, scrollPosition), 500)
    }
  }, [currentView, scrollPosition, isMainContentView])

  // Fetch channel data on component mount or user change

  useEffect(() => {
    if (!id && !booking) return // No channel to fetch
    const eventIUD = id || booking?.channel.event_uid || ''
    getChannel(eventIUD)
      .then((res) => {
        setChannelContent(res)
        setCasting(
          res.tiles.find((tile: TileItem) => tile.tile_type === TileType.CAST),
        )
        setBadgeUrl(res.assets.badge)
        setFullScreenChannel(
          [
            ...res.main.filter((cont: { content: string }) =>
              isVideo(cont.content),
            ),
          ].sort((a, b) => a.y_axis - b.y_axis),
        )
      })
      .catch((err) => console.log('getChannel Error:', err))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  // Update grid content when channelContent changes
  useEffect(() => {
    if (channelContent.main) {
      const sortedGridContent = (channelContent.main as ChannelItem[])
        .filter((cont) => cont.y_axis > 0)
        .sort((a, b) => a.y_axis - b.y_axis)
      setGridContent(sortedGridContent)
    }
  }, [channelContent])

  // Improved ScrollToTop function
  const screenSize = useScreenSize()
  const scrollToTop = () => {
    // We'll use an approach that tries multiple methods

    // For all devices, first try the window scroll
    window.scrollTo({ top: 0, behavior: 'smooth' })

    // For desktop
    if (screenSize.width > 1024) {
      // Try scrolling the main content area
      if (mainContentRef.current) {
        mainContentRef.current.scrollTo({ top: 0, behavior: 'smooth' })
      }

      // Try scrolling any parent with overflow
      let parent = mainContentRef.current?.parentElement
      while (parent) {
        const computedStyle = window.getComputedStyle(parent)
        if (
          computedStyle.overflow === 'auto' ||
          computedStyle.overflow === 'scroll' ||
          computedStyle.overflowY === 'auto' ||
          computedStyle.overflowY === 'scroll'
        ) {
          parent.scrollTo({ top: 0, behavior: 'smooth' })
          break
        }
        parent = parent.parentElement
      }
    }

    // Dispatch resize to fix any layout issues
    setTimeout(() => window.dispatchEvent(new Event('resize')), 100)
  }

  // Render the appropriate view based on the current state
  const renderCurrentView = () => {
    switch (currentView) {
      case VIEW_STATES.CAROUSEL:
        return <Carousel />
      case VIEW_STATES.FULL_SCREEN:
        return <FullScreen />
      case VIEW_STATES.MEET_THE_CAST:
        return <MeetTheCast />
      case VIEW_STATES.MAIN_CONTENT:
      default:
        return (
          <AnimatePresence>
            <motion.section
              id="pre-show-content-container"
              className={`w-full flex flex-col items-center h-full min-h-[100svh] bg-purple`}
              ref={sectionRef}
              initial={{ visibility: 'visible' }}
              animate={{ visibility: 'visible', display: 'flex' }}
              exit={{ opacity: 0, height: 0, transition: { duration: 0.1 } }}
              transition={{ duration: 0.5 }}
            >
              {!!channelContent && !!user && (
                <div
                  className={`fixed flex justify-between z-[500] ${screenSize.width > 1024 ? 'w-[400px]' : 'w-full'}`}
                >
                  <div
                    className="absolute z-[250] cursor-pointer top-0 left-0 pr-6"
                    onClick={() => {
                      navigate('/tickets', { replace: true })
                      setScrollPosition(0)
                    }}
                  >
                    <motion.span
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 0.5, duration: 0.3 }}
                      className="relative m-4 z-50 bg-purple1 text-white rounded-full w-8 h-8 flex items-center justify-center"
                    >
                      <Arrow />
                    </motion.span>
                  </div>

                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: !!channelContent ? 1 : 0 }}
                    transition={{ delay: 0.5 }}
                    id="content-logo"
                    onClick={() => navigate('/tickets', { replace: true })}
                    className={`absolute z-[500] cursor-pointer top-0 right-0 h-8 w-8 m-4`}
                  >
                    <LogoFlip flipInterval={0} />
                  </motion.div>
                </div>
              )}
              <main
                className="w-full flex flex-col items-center overflow-y-auto"
                ref={mainContentRef} // Added ref here
              >
                {!!channelContent.main?.length && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.5 }}
                  >
                    <MainVideo setMainContentLoaded={setMainContentLoaded} />
                    <ContentGrid
                      gridContent={gridContent}
                      badge={channelContent.assets.badge}
                      event_name={channelContent.assets.event_name}
                    />
                  </motion.div>
                )}
                {mainContentLoaded && (
                  <>
                    <ActionBar />
                    <footer className="w-full bg-purple flex flex-col pt-12 pb-32 gap-1">
                      {channelContent.main.length > 2 ? (
                        <p
                          className="text-yellow text-sm cursor-pointer flex flex-col items-center"
                          onClick={() => scrollToTop()}
                        >
                          <MoveUp color="rgba(244, 255, 104, 1)" />
                          {'Back to top'}
                        </p>
                      ) : (
                        <UpSeatLogo />
                      )}
                    </footer>
                  </>
                )}
              </main>
            </motion.section>
          </AnimatePresence>
        )
    }
  }

  return <NavLayout>{renderCurrentView()}</NavLayout>
}
