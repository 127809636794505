import { Input } from '@/components/ui/input'
import { Eye, EyeOff } from 'lucide-react'

export const PasswordInput = ({
  value,
  onChange,
  showPassword,
  userExists,
  confirmPassword,
  toggleVisibility,
  placeholder,
  error,
}: {
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  showPassword: boolean
  toggleVisibility: () => void
  placeholder: string
  confirmPassword?: boolean
  userExists?: boolean
  error?: string | React.ReactNode
}) => (
  <div className="relative flex-col items-center justify-center">
    <Input
      id="login-form-password"
      className={`py-3 ${error && 'border-red'}`}
      variant="transparent"
      placeholder={placeholder}
      type={showPassword ? 'text' : 'password'}
      autoComplete="new-password"
      value={value}
      onChange={onChange}
    />
    <label
      onClick={toggleVisibility}
      className="absolute top-0 right-[8px] p-4 bg-gray-300 hover:bg-gray-400 rounded text-sm text-gray-600 font-mono cursor-pointer"
    >
      {showPassword ? (
        <Eye color={error ? 'rgba(239, 99, 99, 1)' : '#CDC3FB'} size={16} />
      ) : (
        <EyeOff color={error ? 'rgba(239, 99, 99, 1)' : '#CDC3FB'} size={16} />
      )}
    </label>
    {!confirmPassword && !userExists ? (
      <p
        className={`text-[10px] ${error ? 'text-red' : 'text-white'} text-left pl-6 pt-1 text-pretty`}
      >
        {`8+ characters, 1 uppercase, 1 number, 1 symbol`}
      </p>
    ) : error ? (
      <div className="text-red text-[10px] pl-2 pt-1 text-pretty relative">
        {error}
      </div>
    ) : null}
  </div>
)
