import { Booking, Order } from '@/types/booking'
import { create } from 'zustand'

// Define the store's state type
type WalletState = {
  wallet: Booking[] | undefined
  walletOrders: Order[] | undefined
  ticketVideoOnMap: Record<string, boolean> // Map to handle video states per ticket
  setWallet: (wallet: Booking[] | undefined) => void
  removeUpBooking: () => void
  setWalletOrders: (walletOrders: Order[] | undefined) => void
  setTicketVideoOn: (ticketId: string, value: boolean) => void // Per-ticket setter
}

// Create the store
export const useTicketsStore = create<WalletState>((set) => ({
  wallet: undefined,
  walletOrders: undefined,
  ticketVideoOnMap: {}, // Initialize as an empty object
  setWallet: (wallet) => set({ wallet }),
  setWalletOrders: (walletOrders) => set({ walletOrders }),
  removeUpBooking: () => set({ wallet: undefined }),
  setTicketVideoOn: (ticketId, value) =>
    set((state) => ({
      ticketVideoOnMap: { ...state.ticketVideoOnMap, [ticketId]: value },
    })), // Update the state for the specific ticket
}))
