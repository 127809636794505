import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { SeekBar } from './SeekBar'
import { VolumeOff } from '@/assets/volumeOff'
import { VolumeOn } from '@/assets/volumeOn'
import { motion } from 'framer-motion'
import { useFullScreenContentStore } from '@/context/useFullScreenContentStore'
import {
  useContentChannelStore,
  VIEW_STATES,
} from '@/context/useContentChannelStore'
import { ChannelItem } from '@/types/preshow'

interface MainVideoProps {
  setMainContentLoaded: (value: boolean) => void
}

export const MainVideo = ({ setMainContentLoaded }: MainVideoProps) => {
  const params = useParams()
  const navigate = useNavigate()
  const { setMainContent } = useFullScreenContentStore()
  const { channelContent, setView, setScrollPosition } =
    useContentChannelStore()
  const containerRef = useRef<HTMLDivElement>(null)
  const mainVideoRef = useRef<HTMLVideoElement>(null)
  const [isMuted, setIsMuted] = useState(true)
  const [isPlaying, setIsPlaying] = useState(true)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)

  // Update play/pause state based on visibility
  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (timeout) clearTimeout(timeout)
        timeout = setTimeout(() => setIsPlaying(entry.isIntersecting), 100) // Debounce updates
      },
      { threshold: 0.8 },
    )

    if (containerRef.current) observer.observe(containerRef.current)

    return () => {
      observer.disconnect()
      if (timeout) clearTimeout(timeout)
    }
  }, [])

  useEffect(() => {
    if (mainVideoRef.current) {
      mainVideoRef.current.muted = isMuted
    }
  }, [isMuted])

  // Handle video play/pause with buffering logic
  useEffect(() => {
    const video = mainVideoRef.current
    if (!video) return

    let isCanceled = false

    const handlePlayPause = async () => {
      try {
        if (isPlaying) {
          // Check if sufficient buffer is available before playing
          const handleBuffering = () => {
            const buffered = video.buffered
            if (
              buffered.length > 0 &&
              buffered.end(0) > video.currentTime + 2
            ) {
              // Play only if at least 2 seconds are buffered
              video.play().catch((error) => console.log(error))
            } else {
              setTimeout(handleBuffering, 200) // Retry after 200ms
            }
          }
          handleBuffering()
        } else {
          video.pause()
        }
      } catch (error) {
        if (!isCanceled) console.log(error)
      }
    }

    handlePlayPause()

    return () => {
      isCanceled = true // Avoid issues if state changes during cleanup
    }
  }, [isPlaying])

  // Handle metadata load and video readiness
  const handleLoadedMetadata = () => {
    if (mainVideoRef.current) {
      setDuration(mainVideoRef.current.duration)
      setMainContentLoaded(true)
    }
  }

  const handleTimeUpdate = () => {
    if (mainVideoRef.current) setCurrentTime(mainVideoRef.current.currentTime)
  }

  const handleError = () => {
    navigate(`/channel${params.id ?? ''}`)
  }

  return (
    <motion.div
      ref={containerRef}
      id="channel-main-video"
      className={`relative m-0 w-full h-[66vh] cursor-pointer`}
      initial={{ opacity: 0 }}
      animate={{
        opacity: duration ? 1 : 0,
        transition: {
          duration: 0.3,
        },
      }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.3 }}
    >
      <video
        ref={mainVideoRef}
        autoPlay
        data-testid="main-video"
        loop
        muted
        playsInline
        className={`w-full h-[66vh] object-cover ${!duration && 'hidden'}`}
        onLoadedMetadata={handleLoadedMetadata}
        onTimeUpdate={handleTimeUpdate}
        onClick={(e) => {
          e.stopPropagation()
          setMainContent({
            badge: channelContent.assets.badge,
            video_url: (channelContent.main as ChannelItem[])[0].content,
            video_title: (channelContent.main as ChannelItem[])[0].title,
            video_description: (channelContent.main as ChannelItem[])[0]
              .description as string,
            event_name: channelContent.assets.event_name,
          })
          setScrollPosition(0)
          setView(VIEW_STATES.FULL_SCREEN)
        }}
        onError={handleError}
      >
        <source src={(channelContent.main as ChannelItem[])[0].content} />
      </video>

      <div
        className={`absolute bottom-[-1px] w-full flex flex-col gap-2 p-4 pt-16 bg-[linear-gradient(to_top,rgba(0,0,0,.7)_50%,rgba(0,0,0,0)_100%)]`}
      >
        <div className={`flex justify-between items-center`}>
          <div className={`flex gap-2 w-[90%]`}>
            <img
              src={channelContent.assets.badge}
              alt={channelContent.assets.event_name}
              className="w-8 h-8 rounded-[4px]"
              draggable={false}
            />
            <div className="flex flex-col justify-center w-full">
              <p className="font-bold">{channelContent.assets.event_name}</p>
            </div>
          </div>
          <div
            className="cursor-pointer z-50 bg-white/50 rounded-full w-8 h-8 flex items-center justify-center"
            onClick={() => setIsMuted(!isMuted)}
          >
            {isMuted ? <VolumeOff /> : <VolumeOn />}
          </div>
        </div>

        {duration > 0 && (
          <div className="relative flex flex-col items-center bg-transparent py-2">
            <SeekBar
              videoRef={mainVideoRef}
              currentIndex={0}
              duration={duration}
              currentTime={currentTime}
              setCurrentTime={setCurrentTime}
              ref={mainVideoRef}
              isActive
            />
            {/* Custom styles to hide the default thumb */}
            <style>
              {`
                        input[type='range']::-webkit-slider-thumb {
                          -webkit-appearance: none;
                          appearance: none;
                          width: 0;
                          height: 0;
                        }
                        input[type='range']::-moz-range-thumb {
                          width: 0;
                          height: 0;
                        `}
            </style>
          </div>
        )}
      </div>
    </motion.div>
  )
}
