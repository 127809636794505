import { useEffect, useState } from 'react'

type SwipeDirection = 'up' | 'down' | null

export const useSwipeDirection = () => {
  const [swipeDirection, setSwipeDirection] = useState<SwipeDirection>(null)
  const [startY, setStartY] = useState<number | null>(null)
  const [isMouseDown, setIsMouseDown] = useState<boolean>(false)

  useEffect(() => {
    // Touch event handlers
    const handleTouchStart = (e: TouchEvent) => {
      setStartY(e.touches[0].clientY)
    }

    const handleTouchMove = (e: TouchEvent) => {
      if (startY === null) return

      const touchEndY = e.touches[0].clientY
      const deltaY = startY - touchEndY

      // Threshold to detect a swipe (you can adjust this)
      const swipeThreshold = 150

      if (Math.abs(deltaY) > swipeThreshold) {
        setSwipeDirection(deltaY > 0 ? 'up' : 'down')
        setStartY(null) // Reset start position after detecting swipe
      }
    }

    // Mouse event handlers
    const handleMouseDown = (e: MouseEvent) => {
      setIsMouseDown(true)
      setStartY(e.clientY)
    }

    const handleMouseMove = (e: MouseEvent) => {
      if (!isMouseDown || startY === null) return

      const mouseEndY = e.clientY
      const deltaY = startY - mouseEndY

      // Threshold to detect a swipe (you can adjust this)
      const swipeThreshold = 150

      if (Math.abs(deltaY) > swipeThreshold) {
        setSwipeDirection(deltaY > 0 ? 'up' : 'down')
        setStartY(null) // Reset start position after detecting swipe
        setIsMouseDown(false)
      }
    }

    const handleMouseUp = () => {
      setIsMouseDown(false)
      setStartY(null)
    }

    // Register all event listeners
    window.addEventListener('touchstart', handleTouchStart)
    window.addEventListener('touchmove', handleTouchMove)
    window.addEventListener('mousedown', handleMouseDown)
    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('mouseup', handleMouseUp)

    // Also handle the case when mouse leaves the window
    window.addEventListener('mouseout', (e) => {
      if (!e.relatedTarget) {
        handleMouseUp()
      }
    })

    return () => {
      // Clean up all event listeners
      window.removeEventListener('touchstart', handleTouchStart)
      window.removeEventListener('touchmove', handleTouchMove)
      window.removeEventListener('mousedown', handleMouseDown)
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
      window.removeEventListener('mouseout', (e) => {
        if (!e.relatedTarget) {
          handleMouseUp()
        }
      })
    }
  }, [startY, isMouseDown])

  const resetSwipeDirection = () => setSwipeDirection(null)

  return { swipeDirection, resetSwipeDirection }
}
