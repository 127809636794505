import { easeIn, motion } from 'framer-motion'
import { lazy, Suspense, useEffect, useRef, useState } from 'react'

import NavLayout from '@/layouts/NavLayout'
import { NavigationHeader } from '@/components/upseat-ui/wallet/NavigationHeader'
import { SideMenu } from '@/components/upseat-ui/general/SideMenu'
import { UpSeatLogo } from '@/assets/logos/upseat_logo'
import { syncBooking } from '@/services/Booking/syncBooking'
import { useAppStore } from '@/context/useAppStore'
import useConditionalRender from '@/hooks/useConditionalRender'
import { useUserStore } from '@/context/useUserStore'
import { useSeatMap } from '@/hooks/useSeatMap'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { LoginDrawer } from '@/components/upseat-ui/login/LoginDrawer'
import { OnboardingTicket } from '@/components/upseat-ui/wallet/OnboardingTicket'
import UpAppLogo from '@/assets/logos/upseat_logo.svg'
import useScreenSize from '@/hooks/useScreenSize'

const TicketStack = lazy(() =>
  import('@/components/upseat-ui/tickets').then((module) => ({
    default: module.TicketStack,
  })),
)

export const Wallet = () => {
  const [searchParams] = useSearchParams()
  const screenSize = useScreenSize()
  const navigate = useNavigate()
  const { user } = useUserStore()
  const { ui, setAppStore } = useAppStore()
  const { searchingSeats } = useSeatMap()
  const [activeItem, setActiveItem] = useState<number>(0)
  const email = searchParams.get('email')
  const phoneNumber = searchParams.get('phoneNumber')
  const firstName = searchParams.get('firstName')
  const lastName = searchParams.get('lastName')

  // Ref to store the AbortController instance
  const abortControllerRef = useRef<AbortController | null>(
    new AbortController(),
  )

  useEffect(() => {
    if (!user && (!email || !firstName || !lastName)) {
      navigate('/login')
    } else if (localStorage.getItem('user_onboarding')) {
      setAppStore({ ui: { ...ui, onboarding: true } })
      localStorage.removeItem('user_onboarding')
    }
  }, [email, firstName, lastName, navigate, setAppStore, ui, user])

  useEffect(() => {
    if (user) {
      // Check if the URL ends with '/tickets'
      if (!window.location.pathname.endsWith('/tickets')) {
        // Abort the ongoing request if there is one
        abortControllerRef.current?.abort()
        return
      }

      // Create a new AbortController for the current request
      abortControllerRef.current = new AbortController()
      const { signal } = abortControllerRef.current as AbortController

      syncBooking(signal)
        .then()
        .catch((error) => {
          console.log('syncBooking Error:', error)
        })

      return () => {
        // Cleanup: Abort the request when the component unmounts
        abortControllerRef.current?.abort()
      }
    }
  }, [user])

  return (
    <div
      data-testid="tickets"
      id="tickets"
      className="w-full flex justify-center items-center"
    >
      <NavLayout overflowHidden>
        <SideMenu />
        <div
          className={`flex flex-col justify-start items-center h-lvh ${ui.sideMenu ? 'opacity-20 transition-transform duration-200' : ''}`}
        >
          {ui.onboarding ? (
            <div className="py-4 mb-2">
              <span className="[&>svg]:w-[28px] [&>svg]:h-[28px]">
                <UpSeatLogo />
              </span>
            </div>
          ) : (
            <NavigationHeader
              activeItem={activeItem}
              setActiveItem={setActiveItem}
              searchingSeats={searchingSeats}
            />
          )}

          {useConditionalRender(
            !!user && !searchingSeats,
            <motion.ul
              className={`w-full absolute inset-0 flex flex-col items-center`}
              transition={{
                ease: easeIn,
                duration: 0.325,
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              {ui.onboarding ? (
                <OnboardingTicket />
              ) : (
                <Suspense
                  fallback={
                    <div className="h-full flex flex-col justify-center">
                      <motion.img
                        src={UpAppLogo}
                        alt="upSeat-logo"
                        className={`${screenSize.width > 1024 ? 'w-[5vw]' : 'w-[20vw]'}`}
                        initial={{ scale: 0.5 }}
                        animate={{ rotate: 0, scale: 1 }}
                        transition={{
                          type: 'spring',
                          stiffness: 260,
                          damping: 20,
                        }}
                      />
                    </div>
                  }
                >
                  <TicketStack />
                </Suspense>
              )}
            </motion.ul>,
          )}
        </div>
      </NavLayout>

      {useConditionalRender(
        !user && !!email && !!firstName && !!lastName,
        <LoginDrawer
          email={email as string}
          phoneNumber={phoneNumber ? phoneNumber : ''}
          firstName={firstName as string}
          lastName={lastName as string}
        />,
      )}
    </div>
  )
}
