import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

import { CountryCode } from '@/types'
import ReactCountryFlag from 'react-country-flag'
import { countryCodes } from '@/libs/global-vars'

interface CountrySelectProps {
  selected: { code: string; acronym: string; digits: string }
  setValue: (value: CountryCode) => void
  error?: boolean
}

export function CountrySelect({
  selected,
  setValue,
  error,
}: CountrySelectProps) {
  const handleChange = (e: string) => {
    const acronymSelected = countryCodes.find((item) => item.acronym === e)
    setValue({
      code: acronymSelected?.code || '',
      acronym: e,
      digits: acronymSelected?.digits || '',
    })
  }

  return (
    <Select
      defaultValue={selected.acronym}
      onValueChange={(e) => handleChange(e)}
      disabled
    >
      <SelectTrigger
        className={`${
          error && `mb-[18px]`
        } rounded-[40px_4px_4px_40px] w-[7.5rem] [&>span]:text-[1.5rem] [&>span]:relative [&>span]:top-[-1px] items-center justify-center gap-2 p-0`}
      >
        <SelectValue>
          <ReactCountryFlag countryCode={selected.acronym} />
        </SelectValue>
      </SelectTrigger>
      <SelectContent className={`text-purple border-none z-[999]`}>
        <SelectGroup>
          {countryCodes.map(
            (item: { code: string; acronym: string; digits: string }) => {
              return (
                <SelectItem key={item.acronym} value={item.acronym}>
                  <ReactCountryFlag
                    countryCode={item.acronym}
                    style={{
                      fontSize: '2em',
                    }}
                  />
                </SelectItem>
              )
            },
          )}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}
