import { requestWithRetry } from '../axios'

export const getSwapSeats = async (
  order_uid: string,
  start_date: string,
  end_date: string,
) => {
  return await requestWithRetry(
    `swap/seats?order_uid=${order_uid}&start_date=${start_date}&end_date=${end_date}`,
    `get`,
  ).catch((err) => {
    console.log('Error swaping dates', err)
    throw err
  })
}
